.tabla-articulos {
  .tabla_articulos_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        border: 1px solid gray;
      }
    }
  }

  .tabla_articulos_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
}

@media (min-width: 529px) {
  .tabla-articulos {
    .titulos {
      background-color: $default_color;
      color: white;
      font-size: 15px;
      font-weight: bold;
    }

    .tabla_articulos_mobile {
      display: none !important;
    }

    .tabla_articulos_desctop {
      display: block !important;
    }
    .registro-articulos{
      padding-top:1em;
      padding-bottom:1em;

   }
    .registro-articulos:nth-child(even){
       background: #f7f8fa;

    }
   /* .registro-articulos:nth-child(odd){
      background: red;

   }*/
  }
}
