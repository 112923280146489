.tt-mobile-parent-cart {
  .tt-dropdown-menu {
    max-height: 50px;
  }
}

.tt-dropdown-menu {
  max-height: 900px;
  overflow-y: auto;
}

.info-ped-pres {
  max-height: 600px;
  overflow-y: auto;
}

.info-ped-pres::-webkit-scrollbar {
  width: 5px;
}

.info-ped-pres::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.info-ped-pres::-webkit-scrollbar-thumb {
  background: #888;
}

.info-ped-pres::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.btn-nuevo-pedido {
  margin-top: 5px;
}

.tt-cart-content {
  padding: 0.75em !important;
  overflow-y: visible;
}

.tt-cart-list {
  margin-bottom: 1em;
}

.tt-cart-total-row {
  border: none !important;
}

.info-pedido,
.info-presupuesto {
  border: 1px solid lightgray;
  padding: 0.5em;
  margin: 0.5em;
}

@media (min-width: 1025px) {
  .tt-cart .tt-dropdown-menu {
    width: 30% !important;
  }
}

@media (max-width: 1025px) {
  .tt-dropdown-menu {
    max-height: 100% !important;
  }
}
