.banner {
  margin: auto;
  margin-top: 0;
  height: 100% !important;

  .container-indent {
    height: 100%;
  }

  .slick-slider {
    height: 100%;

    .slick-arrow.slick-prev {
      left: 20px;
    }
    .slick-arrow.slick-prev::before {
      color: #e8e8e8;
    }

    .slick-list {
      width: 100%;
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
          height: 100%;

          .slide {
            position: relative;
            height: 100%;

            a {
              height: 100%;
              img {
                height: 100%;
              }
            }
            .slide-caption {
              bottom: 0;
              left: 0;
              position: absolute;
              width: 100%;
              z-index: 2;
              text-align: center;
              background-color: #00000099;
              padding: 25px;

              h3 {
                padding-bottom: 0;
                color: white;
                font-size: 1rem;
              }
              p {
                margin-top: 0;
                color: white;
                font-size: 1rem;
              }
            }
          }

          div:first-child {
            height: 100%;
          }
        }
      }
    }
    .slick-arrow.slick-next {
      right: 20px;
    }
    .slick-arrow.slick-next::before {
      color: #e8e8e8;
    }
  }
}
