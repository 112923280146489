.expedientes {
  span.accion {
    margin: 1em;
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .tipos_expedientes_mobile {
    display: block !important;
    .row {
      align-items: center;
    }
    .col-sm-12.col-md-12.col-lg-12 > .row {
      border: 1px solid gray;
    }
  }
  .tipos_expedientes_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
}

@media (min-width: 529px) {
  .expedientes {
    .tipos_expedientes_mobile {
      display: none !important;
    }
    .tipos_expedientes_desctop {
      display: block !important;
    }
  }
}
