.agrupacion_articulos {
  .tt-price {
    margin-top: 0;
    margin-bottom: 2rem;
  }
}

.condicion_agrupacion {
  margin-bottom: 1rem;
  .titulo_agrupacion {
    font-weight: bold;
  }
  .btn {
    margin: 0.25rem 0.25rem;
  }
}

.tabla_agrupacion {
  display: flex;
  flex-direction: column;

  h5 {
    padding-bottom: 0;
  }

  .tabla_agrupacion_fila {
    display: flex;
    width: 100%;

    .tabla_agrupacion_columna {
      flex-grow: 1;
      margin-bottom: 1rem;
      align-self: center;
      width: 100%;

      .input-group {
        width: 100%;
      }

      .descrip-articulo-hijo-cont {
        font-size: 30px;
        line-height: 40px;
        .descrip-articulo-hijo {
          font-size: medium;
        }
      }

      .tt-price {
        margin: 0;
        .etiqueta-precio {
          font-size: medium;
        }
      }
    }

    .columna_descripcion {
      font-weight: normal;
      padding: 0 0.5rem;
    }

    .columna_precio {
      display: flex;
      justify-content: flex-end;
      padding: 0 0.5rem;
      font-size: 1rem;
      font-weight: bold;
    }

    .columna_carrito {
      padding: 0 0.5rem;
    }
  }
}
