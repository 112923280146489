#informacion-adicional {
  #infoAdicionalContent {
    #documentos {
      .listaDocumentos {
        text-decoration: none;
        list-style: none;
        padding-top: 1em;

        li{
            cursor: pointer;

            a{
                margin-right: 1em;
            }
        }
      }
    }
    
    #auxiliares{
      .listaAuxiliares{
        padding-top: 1em;
        .auxiliares{
          text-align: center;
        }

      }
    }
  }
}
