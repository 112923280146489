.listas-compra {
  form#nueva_lista {
    margin-bottom: 2em;
    align-items: baseline;
    input#nombre_lista {
      margin-right: 1em;
    }
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .listas_compra_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .lista-compra-row {
      margin-top: 1em;
      padding: 0.5em;

      .element-row {
        margin-top: 1em;
      }

      .lista-compra-accion {
        padding: 0.75em;
      }
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        //border: 1px solid gray;
      }
    }
  }

  .listas_compra_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }
}

.listas_compra_detalle_mobile {
  display: block !important;
  .row {
    align-items: center;
  }
  .col-sm-12.col-md-12.col-lg-12 {
    > .row {
      border: 1px solid gray;
    }
  }
}

.listas_compra_detalle_desctop {
  display: none !important;
  .row {
    align-items: center;
    text-align: center;
    margin-top: 1em;
  }
}

.lista-compra-modal {
  .modal-body {
    overflow-y: visible;
    max-height: 500px;
    .tt-title-subpages {
      margin-top: 0;
    }
  }

  .listas_compra_detalle_desctop {
    .quantity-input {
      border: 1px solid $default_color;
    }
  }

  .listas_compra_detalle_mobile {
    .element-row {
      margin: 1em;
      text-align: center;
    }
    .lista-compra-accion {
      padding: 0.75em;
    }
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      color: black;
      font-weight: bold;
      margin: 0.25;
    }
  }
}

@media (min-width: 529px) {
  .listas-compra {
    .listas_compra_mobile {
      display: none !important;
    }

    .listas_compra_desctop {
      display: block !important;

      .lista-compra-row {
        margin-top: 1em;
      }
    }
  }

  .listas_compra_detalle_mobile {
    display: none !important;
  }

  .listas_compra_detalle_desctop {
    display: block !important;
  }
}
