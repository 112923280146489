.registro {
  form {
    .form-group {
      display: block;
      .form-control {
        -webkit-border-top-right-radius: 6px;
        -webkit-border-bottom-right-radius: 6px;
        -moz-border-radius-topright: 6px;
        -moz-border-radius-bottomright: 6px;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
      }
    }
    .btn {
      -webkit-border-top-left-radius: 6px;
      -webkit-border-bottom-left-radius: 6px;
      -moz-border-radius-topleft: 6px;
      -moz-border-radius-bottomleft: 6px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
  }
  .tt-item {
    border: none;
  }
}
