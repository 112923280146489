.pedidos-pendientes {
  .pedidos_pendientes_mobile {
    display: block !important;
    .row {
      align-items: center;
    }

    .pedidos-pendientes-row {
      margin-top: 1em;
      padding: 0.5em;

      .element-row {
        margin-top: 1em;
      }

      .lista-compra-accion {
        padding: 0.75em;
      }
    }

    .col-sm-12.col-md-12.col-lg-12 {
      > .row {
        border: 1px solid gray;
      }
    }
  }
  .pedidos_pendientes_desctop {
    display: none !important;
    .row {
      align-items: center;
    }
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }
}

.pedidos-pendientes-modal {
  .modal-body {
    overflow-y: visible;
    max-height: 570px;
    .tt-title-subpages {
      margin-top: 0;
    }
  }
  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      color: black;
      font-weight: bold;
      margin: 0.25;
    }
  }

  .pedidos_pendientes_detalle_desctop {
  }

  .pedidos_pendientes_detalle_mobile {
    .element-row {
      margin: 1em;
      text-align: center;
    }
    .pedidos-pendientes-accion {
      padding: 0.75em;
    }
  }
}

.pedidos_pendientes_detalle_mobile {
  display: block !important;
  .row {
    align-items: center;
  }
  .col-sm-12.col-md-12.col-lg-12 {
    > .row {
      border: 1px solid gray;
    }
  }
}

.pedidos_pendientes_detalle_desctop {
  display: none !important;
  .row {
    align-items: center;
  }
}

@media (min-width: 529px) {
  .pedidos-pendientes {
    .pedidos_pendientes_mobile {
      display: none !important;
    }

    .pedidos_pendientes_desctop {
      display: block !important;
    }

    .pedidos-pendientes-row {
      margin-top: 1em;
    }
  }

  .pedidos_pendientes_detalle_mobile {
    display: none !important;
  }

  .pedidos_pendientes_detalle_desctop {
    display: block !important;
  }
}
