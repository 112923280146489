.articulo {
  .tt-product-single-carousel-vertical {
    display: none;
  }

  .tt-impuestos {
    color: #191919;
    font-family: "Hind", sans-serif;
    font-size: 14px;
  }

  .tt-product-single-img {
    width: 100%;
  }
}

@media (min-width: 529px) {
  .articulo {
    .tt-product-single-carousel-vertical {
      display: block;

      .slick-vertical .slick-list .slick-track {
        height: auto !important;
      }
    }
    .tt-product-single-img {
      width: 82.7%;
    }
  }
}

@media (min-width: 1300px) {
  .articulo {
    > .container-indent {
      > .container {
        max-width: 1000px;
      }
    }
  }
}

@media (min-width: 1500px) {
  .articulo {
    > .container-indent {
      > .container {
        max-width: 1200px;
      }
    }
  }
}

@media (min-width: 1700px) {
  .articulo {
    > .container-indent {
      > .container {
        max-width: 1400px;
      }
    }
  }
}

@media (min-width: 1900px) {
  .articulo {
    > .container-indent {
      > .container {
        max-width: 1600px;
      }
    }
  }
}
