/*@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,100&display=swap");*/ /*color importar fuente*/

$default_font: "Hind", sans-serif; /*fuente web*/
$default_bg_color: #ffffff; /*color fondo web*/
$default_text_color: #777777; /*color defecto texto*/

$default_color: #1a6abf; /*color defecto, botones por ejemplo*/
$secondary_color: #124983; /*color de los hover de los botones*/
$default_color2: #191919; /*color defecto secundario, mejor colores oscuros*/

$h_scheme01_bg: #303030; /*color cabecera*/

$menu_familias_bg: $default_color;

$menu_informes_bg: $default_color;

$warning: $default_color; /*cambia el color de los warning de bootstrap usados para los avisos*/

$breadcrumb_bg: $default_bg_color;

$tiras_color: white;
