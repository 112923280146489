html,
body,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;

  .container {
    flex: 1;
  }
}

.menu-informes {
  .tt-desctop-menu .tt-megamenu-col-02 .dropdown-menu {
    z-index: 1;
  }
}

.contenedor_informes {
  .sel_informe_txt {
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    margin-top: 30px;
  }

  .form-default {
    margin: 30px;
    padding: 20px;
    border: 1px solid #f7f8fa;
    border-radius: 10px;
  }

  .btn-plugin {
    padding: 8px 10px;
    height: auto;
    margin-right: 10px;

    svg {
      margin-right: 5px;
    }
  }

  .tt-shopcart-table {
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .informes_mobile {
    display: block;
    .row {
      align-items: center;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .imprimir {
      margin-bottom: 20px;
      justify-content: flex-end;
    }
    > .col-sm-12.col-md-12.col-lg-12 {
      > .row:not(.imprimir) {
        border: 1px solid gray;
      }
    }

    .col-plugins {
      margin-top: 3px;
      margin-bottom: 3px;
    }
  }

  .informes_desctop {
    display: none;
    .row {
      align-items: center;
      margin-top: 3px;
      margin-bottom: 3px;
    }
    .imprimir {
      margin-bottom: 20px;
      justify-content: flex-end;
    }
  }
}

@media (min-width: 529px) {
  .contenedor_informes {
    .informes_mobile {
      display: none;
    }
    .informes_desctop {
      display: block;
      .row {
        .col {
          flex-basis: unset;
          flex-grow: unset;
          max-width: unset;
        }
      }
      .col-plugins {
        display: flex;
        flex-direction: column;
        align-items: center;
        .btn-plugin {
          margin: 0.2em;
        }
      }
    }
  }
}

@media (min-width: 1350px) {
  .contenedor_informes {
    max-width: 1300px;
  }
}

@media (min-width: 1450px) {
  .contenedor_informes {
    max-width: 1400px;
  }
}

@media (min-width: 1550px) {
  .contenedor_informes {
    max-width: 1500px;
  }
}
