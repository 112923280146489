.conversaciones {
  span.accion {
    margin: 1em;
  }

  .tt-shopcart-table {
    text-align: center !important;
    .titulos {
      font-weight: bold;
      margin: 0.25;
    }
  }

  .tipos_conversaciones_mobile {
    display: block !important;
    .row {
      align-items: center;
    }
    .element-row {
      margin: 1em;
      text-align: center;
    }
    .pedidos-pendientes-row {
      margin-top: 1em;
      padding: 0.5em;

      .element-row {
        margin-top: 1em;
      }

      .pedidos-pendientes-accion {
        padding: 0.75em;
      }
    }
    .col-sm-12.col-md-12.col-lg-12 > .row {
      border: 1px solid gray;
    }
  }
  .tipos_conversaciones_desctop {
    display: none !important;
    .row {
      align-items: center;
      text-align: center;
      margin-top: 1em;
    }
  }

  .btn-new-conversation {
    align-items: center;
    width: 10px;
  }

  ///////////////////
  .btn-primary {
    color: #fff;
    background-color: $default_color;
    border-color: $default_color;
  }
  .btn {
    margin-right: 12px;
  }
  .chat-title {
    margin-left: 5%;
  }

  .conver-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mio {
    display: flex;
    justify-content: flex-end;
  }

  .tuyo {
    display: flex;
    justify-content: flex;
  }
}
#contenidoChat {
  background: url("../images/chat2.png");
  min-width: 100%;
  display: flex;
  flex-direction: column;
  ///////////////////
  .btn-primary {
    color: #fff;
    background-color: $default_color;
    border-color: $default_color;
  }
  .btn {
    margin-right: 12px;
  }
  .chat-title {
    margin-left: 5%;
  }

  .conver-row {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mio {
    display: flex;
    justify-content: flex-end;
  }

  .mio p {
    text-align: right;
    font-family: Helvetica;
    color: black;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px;
    background-blend-mode: soft-light;
    box-shadow: 0 0.3px 0.2px rgba(0, 0, 0, 0.034),
      0 1.3px 1px rgba(0, 0, 0, 0.048), 0 2.5px 2px rgba(0, 0, 0, 0.06),
      0 4.46px 3.4px rgba(0, 0, 0, 0.072), 0 8.2px 6.6px rgba(0, 0, 0, 0.086),
      0 20px 16px rgba(0, 0, 0, 0.12);
  }

  .tuyo {
    display: flex;
    justify-content: flex;
  }

  .tuyo p {
    text-align: left;
    font-family: Helvetica;
    color: black;
    background-color: #fff;
    border-radius: 8px;
    padding: 7px;
    background-blend-mode: soft-light;
    box-shadow: 0 0.3px 0.2px rgba(0, 0, 0, 0.034),
      0 1.3px 1px rgba(0, 0, 0, 0.048), 0 2.5px 2px rgba(0, 0, 0, 0.06),
      0 4.46px 3.4px rgba(0, 0, 0, 0.072), 0 8.2px 6.6px rgba(0, 0, 0, 0.086),
      0 20px 16px rgba(0, 0, 0, 0.12);
  }
}
.conversacionesModal {
  .ReactModal__Content.ReactModal__Content--after-open {
    padding: 0 !important;
    overflow-x: hidden !important;
    display: flex;
    width: 80% !important;
    height: 80% !important;

    #contenidoChat {
      background: url("../images/chat2.png");
      min-width: 100%;
      display: flex;
      flex-direction: column;
    }

    .boton-enviar-mensaje {
      background-color: $default_color;
    }

    .overlay {
      background-color: $default_color;
      width: 100%;
      height: 100%;
      position: absolute;
      mix-blend-mode: color;
    }

    .conver-span {
      flex: 0;
    }

    .chat-title {
      flex: 0;
    }

    .mensajes-conversacion {
      overflow: visible;
      overflow-y: auto !important;
      flex: 1;
    }

    .mensajes-conversacion::-webkit-scrollbar {
      width: 5px;
    }

    .mensajes-conversacion::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    .mensajes-conversacion::-webkit-scrollbar-thumb {
      background: #888;
    }

    .mensajes-conversacion::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.input-mensaje {
  flex: 0;
  display: none;
  font-family: Helvetica;
  margin: 0.5em;
  bottom: 0;
  left: 0;
  right: 0;
  justify-content: center;
  position: relative;

  .boton-enviar-mensaje {
    width: 10%;
  }

  .mensaje {
    width: 80%;
    padding: 0.5em !important;
  }
}

///////////////////

@media (min-width: 1080px) {
  .conversaciones {
    .tipos_conversaciones_mobile {
      display: none !important;
    }
    .tipos_conversaciones_desctop {
      display: block !important;
    }
  }
  .conversacionesModal {
    .ReactModal__Content.ReactModal__Content--after-open {
      width: 35% !important;
      height: 70% !important;
    }
  }
}
