.novedades_ofertas {
    display: flex;
    padding: 50px 50px;
    .novedades {
        flex-grow: 1;
        .tt-title {
            display: none;
        }
        h2 {
            text-align: center;
            color: $default_color;
        }
    }
}