.tira {
  background-color: $default_color;
  padding: 60px 20px;
  display: flex;
  width: 100%;
  align-content: space-between;
  margin-top: 0 !important;

  .tira_caja {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white-color;
    width: 100%;

    .tira_icono {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: $tiras_color;
    }

    .tira_imagen {
      height: 3rem;
      margin-bottom: 1rem;

      img {
        height: 100%;
      }
    }

    .tira_titulo {
      font-size: 1.4rem;
      font-weight: bold;
      color: $tiras_color;
      margin-bottom: 10px;
      text-align: center;
    }

    .tira_texto {
      text-align: center;
    }
  }
}
