@media (min-width: 1300px) {
  #root {
    > .container {
      max-width: 1100px;
    }
  }
}

@media (min-width: 1500px) {
  #root {
    > .container {
      max-width: 1300px;
    }
  }
}

@media (min-width: 1700px) {
  #root {
    > .container {
      max-width: 1500px;
    }
  }
}

@media (min-width: 1900px) {
  #root {
    > .container {
      max-width: 1700px;
    }
  }
}
