.listado-codigo-barras {
  display: flex;
  flex-direction: row;
  .codigo-barras {
    display: block;

    svg {
      max-width: 150px;
      height: auto;
    }
  }
}
