.redes_sociales {
  position: fixed;
  top: 50%;
  left: 0;
  transform: translate(0px, -50%);
  .redes_sociales_container {
    border: solid 2px #fff;
    .red_social_caja {
      border-bottom: solid 2px #fff;
      padding: 5px;
      text-align: center;
      a {
        font-size: 2rem;
      }
    }
    .red_social_caja:last-of-type {
      border-bottom: none;
    }
  }
}
