.subpresentaciones {
  .titulo-subpresentaciones {
    font-weight: bold;
  }

  button{
      margin:0.5em;
  }
  
  margin-bottom: 1rem;
}
