.control_anadir_carrito {
  width: 100%;
  .input-group {
    width: 100%;
    .detach-quantity-desctope {
      width: 100%;

      .tt-input-counter {
        width: 100%;
        max-width: unset;
      }
    }
  }
  .input-group-append {
    width: 100%;
    button {
      width: 100%;
    }
  }

  .mobile {
    display: block;
  }

  .desctop {
    display: none;
  }
}

.tabla_agrupacion_columna {
  .control_anadir_carrito {

  }
}

@media (min-width: 529px) {
  .control_anadir_carrito {
    width: 100%;
    .form-control {
      width: 50%;
    }
    .input-group-append {
      width: 50%;
    }

    .mobile {
      display: none !important;
    }
    .desctop {
      display: flex !important;
    }
  }

  .articulo .control_anadir_carrito .input-group {
    width: 40%;
  }

  .tabla-articulos .tt-article-table .input-group{
    width: 58%;
    .input-group-append {
      width: 0%;
    }
  }

  .tabla_agrupacion_columna {
    .control_anadir_carrito {
      
    }
  }
}
