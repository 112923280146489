.tt-shopcart-table {
  .linea-carrito {
    text-align: center;
    align-items: baseline;
    div[class^="col-"] {
      padding: 10px;
    }
    .detach-quantity-desctope {
      width: fit-content;
      margin: auto;
    }
  }
}

.tt-shopcart-btn {
  text-align: end;
  margin-bottom: 1em;
}

.totales {
  text-align: end;
  justify-content: end;
  margin-bottom: 2em;
}

.totales .titulo {
  font-weight: bold;
  color: black;
}

.titulo-lineas-pedido {
}

.titulo-lineas-pedido .tt-title-subpages {
  padding: 0 !important;
  margin-top: 0 !important;
}

.lineas-pedido {
  margin-bottom: 1em;
}
.titulo-lineas-presupuesto {
}
.titulo-lineas-presupuesto .tt-title-subpages {
  padding: 0 !important;
  margin-top: 0 !important;
}

.lineas-presupuesto {
  margin-bottom: 1em;
}
