._reset-ul-style{
    list-style: none;
    margin: 0;
    padding: 0;
}
._list-inline-item{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
._list-block-item{
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}

._tt-point{
    // .tt-point-v-t - vertical top
    // .tt-point-v-b - vertical bottom

    // .tt-point-h-l - horizontal left
    // .tt-point-h-r - horizontal right

    .tt-point-v-t{
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .tt-point-v-b{
        -webkit-align-items: flex-end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

    .tt-point-h-l{
         -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
    }
    .tt-point-h-r{
         -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        -webkit-align-content: flex-start;
        -ms-flex-line-pack: start;
        align-content: flex-start;
    }
}