/*---------------------------------------*/
/*--------  0. Personalizaciones --------*/
/*---------------------------------------*/

@import "../../pesonalizaciones.scss";

/*---------------------------------------*/

@import url("https://fonts.googleapis.com/css?family=Hind:300,400,500,600");
/* font */
$default_font: "Hind", sans-serif !default;
$default_font2: $default_font;
/* font icon*/
$default_font_icon: "wokiee";
$default_bg_color: #ffffff !default;
$default_text_color: #777777 !default;
$link_color_base: $default_text_color;
$simple_links_hover_speed: 0.2s;
$default_title_color: #191919 !default;
/* Colors */
$default_color: #2879fe !default;
$default_color2: #191919 !default;
$secondary_color: #2879fe !default;
$border: #e9e7e7;
$border_radius: 6px;
$border_radius_small: 2px;

$base-color: $default_color;
$base-dark-color: $default_color2;
$white-color: #ffffff;
$tt-light-green-color: #08da97;
$color-bg-01: #add8e6;
$color-bg-02: #fe9001;

$color-bg-03: #808080;
$color-bg-04: #0000ff;
$color-bg-05: #00ff00;

$color-bg-06: #46d3bb;
$color-bg-07: #f66c47;
$color-bg-08: #ffffff;
$color-bg-09: #e5e5e5;
$color-bg-10: #fed533;
$color-bg-11: #f36b26;
$color-bg-12: #7bba3c;
$color-bg-13: #1790c8;
$color-bg-14: #d82a90;
$color-bg-15: #7f7f00;
$color-bg-16: #e7352b;
$color-bg-17: #8d429f;
$color-bg-18: #777878;

/*
	loader
*/
$loader_site_bg: #ffffff;
$loader_figure_bg: $default_color;
$loader_figure_border: #ffffff;
$loader_width: 250px;
$loader_dot_size: 15px;
/*
	Back to top
*/
$backToTop_desctope_bg: rgba($default_color, 0.75);
$backToTop_desctope_bg_hover: rgba(48, 48, 48, 1);
$backToTop_desctope_text: #ffffff;
$backToTop_desctope_text_hover: #ffffff;
$backToTop_mobile_bg: #ffffff;
$backToTop_mobile_bg_hover: #ffffff;
$backToTop_mobile_text: $default_color2;
$backToTop_mobile_text_hover: $default_color;
/*
	Breadcrumb
*/
$breadcrumb_bg: #f7f8fa !default;
$breadcrumb_text: $default_text_color;
$breadcrumb_link: $breadcrumb_text;
$breadcrumb_link_hover: $default_color;
/*
	Form
*/
$form_placeholder: $default_text_color;
$form_control_bg: #f7f8fa;
$form_control_bg_focus: #ffffff;
$form_control_text: $default_text_color;
$form_control_border: #f7f8fa;
$form_control_border_focus: $default_color;
$form_control_label: $default_color2;
$form_required_text: $default_text_color;
$checkbox_label: $default_color2;
$checkbox_bg: #f7f8fa;
$checkbox_bg_active: $default_color;
$checkbox_border: #cdcdcd;
$checkbox_icon_active: #ffffff;
$checkbox_label_hover: $default_color;
$radio_text: $default_text_color;
$radio_text_hover: $default_color;
$radio_bg: #f7f8fa;
$radio_bg_active: $default_color;
$radio_bg_border: #cdcdcd;
$errors_bg: #f8353e;
$errors_text: #ffffff;
$errors_icon: $errors_text;
/*
	typography
*/
/* button */
$btn_colorbg: $default_color;
$btn_colortext: #ffffff;
$btn_colorbg_hover: $secondary_color;
$btn_colortext_hover: #ffffff;
$btnwhite_colorbg: #ffffff;
$btnwhite_colortext: $default_color2;
$btnwhite_colorbg_hover: $default_color2;
$btnwhite_colortext_hover: #ffffff;
$btndark_colorbg: $default_color2;
$btndark_colortext: #ffffff;
$btndark_colorbg_hover: $default_color;
$btndark_colortext_hover: #ffffff;
$btnborder_colorbg: transparent;
$btnborder_colortext: $default_color;
$btnborder_colorborder: $default_color;
$btnborder_colorbg_hover: $default_color2;
$btnborder_colortext_hover: #ffffff;
$btnborder_colorborder_hover: $default_color2;
$btnborder01_colorbg: transparent;
$btnborder01_colortext: #999999;
$btnborder01_colorborder: #999999;
$btnborder01_colorbg_hover: $default_color2;
$btnborder01_colortext_hover: #ffffff;
$btnborder01_colorborder_hover: $default_color2;
$btnlink_colortext: $default_color;
$btnlink_colortext_hover: $default_color2;
$btnlink02_colortext: $default_color;
$btnlink02_colortext_hover: $default_color2;
$btnunderline_colortext: $default_color;
$btnunderline_colorborder: $default_color;
$btnunderline_colortext_hover: $default_color2;
$btnunderline_colorborder_hover: transparent;
$btnunderline_white_colortext: #ffffff;
$btnunderline_white_colorborder: #ffffff;
$btnunderline_white_colortext_hover: $default_color2;
$btnunderline_white_colorborder_hover: transparent;
$ttlink_text: $default_color;
$ttlink_text_hover: $default_color2;
$default_title: $default_title_color;
$default_title_hover: $default_color;
$default_title_subpages_border: $border;
$title_border: $border;
$mark_bg: $default_color2;
$mark_text: #ffffff;
$hr_border: $border;
$contentInfo_title: $default_color2;
$contentInfo_title_hover: $default_color;
$contentInfo_text: $default_text_color;
$contentInfo_text_large: $default_color2;
$contentInfo_text_small: #999999;
/* list */
$list_dash_text: $default_text_color;
$list_dash_bullet: $default_text_color;
$list_dot_text: $default_text_color;
$list_dot_large_text: $default_color2;
$list_dot_link: $default_color2;
$list_dot_link_hover: $default_color;
$list_dot_bullet: $default_color;
$list_dot_bullet_sub: #191918;
$list_number_text: $default_text_color;
$list_content_text: $default_text_color;
$blockquote_bg: #f7f8fa;
$blockquote_icon: $default_color;
$blockquote_title: $default_title_color;
$blockquote_title_hover: $default_color;
$blockquote_title_description_text01: #999999;
$blockquote_title_description_text02: $default_color2;
$table01_border: $border;
$table01_thead_text: $default_color2;
$table02_border: $border;
$table02_title: $default_title_color;
$table03_title: $default_color2;
$block_title_text: $default_title_color;
$block_title_link: $default_color;
$block_title_link_hover: $block_title_link;
$block_title_link_border: $default_color;
$block_title_link_border_hover: transparent;
$block_title_small_text: $default_title_color;
$block_title_small_link: $default_color;
$block_title_small_link_hover: $block_title_small_link;
$block_title_small_link_border: $default_color;
$block_title_small_link_border_hover: transparent;
$block_title_description_text: $default_title_color;
$faq_title: $default_title_color;
$faq_title_hover: $default_color;
$about_box_text: #ffffff;
$about_box_title: #ffffff;
$about_box_blockquote_icon: #ffffff;
$about_box_blockquote_title: #ffffff;
$about_box_blockquote_text: #ffffff;
$about_box_blockquote_title_mobile: $default_title_color;
$about_box_blockquote_icon_mobile: $default_color;
$contact_box_text: #ffffff;
$contact_box_title: #ffffff;
$contact_box_link: #ffffff;
$contact_box_link_hover: $default_color;
$contact_info_icon: $default_color;
$contact_info_title: $default_title_color;
$box_thumb_title: $default_title_color;
$box_thumb_title_hover: $default_color;
$page404_bg: #ffffff;
$page404_text: $default_color2;
$page404_title: $default_color2;
$empty_cart_text: $default_text_color;
$empty_cart_icon: #ebecee;
$empty_cart_title: $default_title_color;
$empty_search_text: $default_text_color;
$empty_search_icon: #ebecee;
$empty_search_title: $default_title_color;
$coming_soon_title01: $default_title_color;
$coming_soon_title02: $default_title_color;
$socialIcon_pageContent_icon: #d8d8d8;
$socialIcon_pageContent_icon_hover: $default_color2;
$wrapper_socialIcon_bg: #f7f8fa;
$loginForm_border: $border;
$loginForm_links: $default_color;
$loginForm_links_hover: $default_color2;
$gift_title: $default_title_color;
$gift_price: #ffffff;
$gift_code: #ffffff;
$gift_code_border: #ffffff;
$gift_code_text: $default_color2;
$servicesBlock_icon: $default_color;
$servicesBlock_title_text: $default_title_color;
$servicesBlock_description_text: $default_text_color;
$servicesBlock_title_text_hover: $default_color;
$img_box_border: $border;
$img_box_border_hover: $default_color;
$layout_vertical_title: $default_title_color;
$layout_vertical_title_hover: $default_color;
$layout_vertical_info: #999999;
$layout_vertical_info_hover: $default_color;
$layout_vertical_price: $default_color2;
$layout_vertical_price_new: #f8353e;
$layout_vertical_price_old: $layout_vertical_price;
$tabsDefault_nav_text: #999999;
$tabsDefault_nav_text_hover: $default_color2;
$tabsDefault_nav_text_active: $tabsDefault_nav_text_hover;
$items_categories_title_hover: $default_color;
$items_categories_list_text: $default_text_color;
$items_categories_list_text_hover: $default_color;
$items_categories_list_text_active: $items_categories_list_text_hover;
$ttLoader_bg: $default_color;
/*
	boxed layout
*/
$boxed_bg_color: #f7f8fa;
$boxed_stuck_nav_shadow: rgba(0, 0, 0, 0.45);
/*
	header
*/
$top_panel_bg: $default_color;
$top_panel_text: #ffffff;
$top_panel_link: #ffffff;
$top_panel_link_border: #ffffff;
$top_panel_btn_close: #ffffff;
$top_panel_btn_close_hover: #ffffff;
/*logo if text*/
$logo_text_color: $default_color;
$logo_text_color_hover: $default_color2;
/*account, language, currency*/
$header_dropdown_toggle_btn_text: $default_color2;
$header_dropdown_toggle_btn_text_hover: $default_color;
$header_dropdown_toggle_btn_text_active: $default_color;
$header_dropdown_toggle_bg: #ffffff;
$header_dropdown_toggle_box_shadow: rgba(0, 0, 0, 0.08);
$header_popup_mobile_bg: rgba(0, 0, 0, 0.5);
/*account layout*/
$account_layout_link: $default_color2;
$account_layout_link_hover: $default_color;
$account_layout_icon: $default_color2;
$account_layout_icon_hover: $default_color;
/*language and currency layout*/
$multi_layout_link: $default_color2;
$multi_layout_link_hover: $default_color;
$multi_layout_link_hover_active: $default_color;
$multi_layout_icon: $default_color2;
$multi_layout_icon_hover: $default_color;
$multi_layout_icon_active: $default_color;
$multi_layout_mobile_separator: $border;
$multi_layout_mobile_title_color: $default_color2;
$multi_layout_mobile_close: $default_text_color;
$multi_layout_mobile_close_hover: $default_color;
$multi_layout_mobile_close_icon: $multi_layout_mobile_close;
$multi_layout_mobile_close_icon_hover: $multi_layout_mobile_close_hover;
$multi_layout_mobile_close_separator: $border;
/*cart layout*/
$header_cart_badge_bg: $default_color;
$header_cart_badge_text: #ffffff;
$header_cart_dropdown_empty_icon: #cdcdcd;
$header_cart_dropdown_empty_text: $default_text_color;
$header_cart_title: $default_color2;
$header_cart_title_hover: $default_color;
$header_cart_add_info_text: #999999;
$header_cart_quantity: $default_text_color;
$header_cart_price: $default_color2;
$header_cart_btn_close: $default_color2;
$header_cart_btn_close_hover: $default_color;
$header_cart_separator: $border;
$header_cart_total_title: $default_color2;
$header_cart_total_price: $default_color2;
/*search layout*/
$search_desctope_popup_shadow: rgba(0, 0, 0, 0.08);
$search_desctope_popup_bg: #ffffff;
$search_desctope_search_input_border: #eceaea;
$search_desctope_search_input_text: $default_color2;
$search_desctope_search_input_placeholder: $default_color2;
$search_desctope_search_btn_search: #333333;
$search_desctope_search_btn_search_hover: $default_color;
$search_desctope_search_btn_close: $default_text_color;
$search_desctope_search_btn_close_hover: $default_color;
$search_mobile_popup_bg: #ffffff;
$search_mobile_popup_bg_stuck: #ffffff;
$search_mobile_search_input_text: $default_text_color;
$search_mobile_search_input_placeholder: $default_color2;
$search_mobile_search_btn_search: $default_color;
$search_mobile_search_btn_close: $default_text_color;
$search_mobile_search_btn_close_hover: $default_color;
$search_mobile_results_bg: #ffffff;
$search_mobile_results_shadow: rgba(0, 0, 0, 0.04);
$search_mobile_results_separator_stuck: $border;
$search_mobile_results_item_hover: #f7f8fa;
$search_mobile_results_text: $default_text_color;
$search_mobile_results_title: #333333;
$search_mobile_results_price: $default_color2;
$search_mobile_results_price_new: #f8353e;
$search_mobile_results_price_old: $search_mobile_results_price;
$search_desctope_results_bg: #ffffff;
$search_desctope_results_text: $default_text_color;
$search_desctope_results_title: #333333;
$search_desctope_results_title_hover: $default_color;
$search_desctope_results_price: $default_color2;
$search_desctope_results_price_new: #f8353e;
$search_desctope_results_price_old: $default_color2;
$search_results_btn_text: $default_color;
$search_results_btn_text_hover: $default_color2;
/*desctope menu */
$menu_desctope_promo_text: $default_text_color;
$menu_desctope_promo_title: $default_color2;
$menu_desctope_listing_img_border: $border;
$menu_desctope_listing_img_border_hover: $default_color2;
$menu_desctope_dropdown_bg: #ffffff;
$menu_desctope_stuck_bg: #ffffff;
$menu_desctope_stuck_shadow: rgba(0, 0, 0, 0.3);
$menu_desctope_dropdown_shadow: rgba(0, 0, 0, 0.08);
$menu_desctope_dropdown_badge_text: #ffffff;
$menu_desctope_dropdown_badge_bg: transparent;
$menu_desctope_dropdown_badge_new_text: $menu_desctope_dropdown_badge_text;
$menu_desctope_dropdown_badge_new_bg: #1393f5;
$menu_desctope_dropdown_badge_fatured_text: $menu_desctope_dropdown_badge_text;
$menu_desctope_dropdown_badge_fatured_bg: #fdbc20;
$menu_desctope_dropdown_badge_sale_text: $menu_desctope_dropdown_badge_text;
$menu_desctope_dropdown_badge_sale_bg: #f8353e;
$menu_desctope_dropdown_product_title: $default_title_color;
$menu_desctope_dropdown_product_title_hover: $default_color;
$menu_desctope_dropdown_product_title_active: $menu_desctope_dropdown_product_title_hover;
$menu_desctope_dropdown_submenu_title: $default_title_color;
$menu_desctope_dropdown_submenu_title_hover: $default_color;
$menu_desctope_dropdown_submenu_title_active: $menu_desctope_dropdown_submenu_title_hover;
$menu_desctope_dropdown_link: $default_text_color;
$menu_desctope_dropdown_link_hover: $default_color;
$menu_desctope_dropdown_link_arrow: $default_color2;
$menu_desctope_dropdown_submenu_bg: #ffffff;
$menu_desctope_dropdown_submenu_shadow: rgba(0, 0, 0, 0.08);
$menu_desctope_dropdown_submenu_link: $default_text_color;
$menu_desctope_dropdown_submenu_link_hover: $default_color;
$menu_desctope_dropdown_img_preview: rgba(0, 0, 0, 0.08);
$menu_desctope_link_text: $default_color2;
$menu_desctope_link_bg: transparent;
$menu_desctope_link_text_hover: $default_color;
$menu_desctope_link_bg_hover: transparent;
$menu_desctope_link_text_active: $menu_desctope_link_text_hover;
$menu_desctope_link_bg_active: $menu_desctope_link_bg_hover;
$menu_desctope_link_text_selected: $menu_desctope_link_text_hover;
$menu_desctope_link_bg_selected: $menu_desctope_link_bg_hover;
$menu_desctope_hover_02_link_text: $default_color2;
$menu_desctope_hover_02_link_bg: transparent;
$menu_desctope_hover_02_link_text_hover: #ffffff;
$menu_desctope_hover_02_link_bg_hover: $default_color;
$menu_desctope_hover_02_link_text_active: $menu_desctope_hover_02_link_text_hover;
$menu_desctope_hover_02_link_bg_active: $menu_desctope_hover_02_link_bg_hover;
$menu_desctope_hover_02_link_text_selected: $menu_desctope_hover_02_link_text_hover;
$menu_desctope_hover_02_link_bg_selected: $menu_desctope_hover_02_link_bg_hover;
$h_scheme01_bg: #303030 !default;
$h_scheme01_text: #888888;
$h_scheme01_box_info_link: #888888;
$h_scheme01_box_info_link_hover: $h_scheme01_box_info_link;
$h_scheme01_box_info_link_icon: #ffffff;
$h_scheme01_social_icon_link: $default_text_color;
$h_scheme01_social_icon_link_hover: #ffffff;
$h_scheme01_menu_desctope_link_text: #ffffff;
$h_scheme01_menu_desctope_link_bg: transparent;
$h_scheme01_menu_desctope_link_text_hover: $default_color;
$h_scheme01_menu_desctope_link_bg_hover: transparent;
$h_scheme01_menu_desctope_link_text_active: $h_scheme01_menu_desctope_link_text_hover;
$h_scheme01_menu_desctope_link_bg_active: $h_scheme01_menu_desctope_link_bg_hover;
$h_scheme01_menu_desctope_link_text_selected: $h_scheme01_menu_desctope_link_text_hover;
$h_scheme01_menu_desctope_link_bg_selected: $h_scheme01_menu_desctope_link_bg_hover;
$h_scheme01_menu_desctope_hover_02_link_text: #ffffff;
$h_scheme01_menu_desctope_hover_02_link_bg: transparent;
$h_scheme01_menu_desctope_hover_02_link_text_hover: #ffffff;
$h_scheme01_menu_desctope_hover_02_link_bg_hover: $default_color;
$h_scheme01_menu_desctope_hover_02_link_text_active: $h_scheme01_menu_desctope_hover_02_link_text_hover;
$h_scheme01_menu_desctope_hover_02_link_bg_active: $h_scheme01_menu_desctope_hover_02_link_bg_hover;
$h_scheme01_menu_desctope_hover_02_link_text_selected: $h_scheme01_menu_desctope_hover_02_link_text_hover;
$h_scheme01_menu_desctope_hover_02_link_bg_selected: $h_scheme01_menu_desctope_hover_02_link_bg_hover;
$h_scheme01_dropdown_toggle_btn_text: #ffffff;
$h_scheme01_dropdown_toggle_btn_text_hover: $default_color;
$h_scheme01_menu_toggle: #ffffff;
$h_scheme02_bg: #f7f8fa;
$h_scheme02_text: #888888;
$h_scheme02_box_info_link: #888888;
$h_scheme02_box_info_link_hover: $h_scheme02_box_info_link;
$h_scheme02_box_info_link_icon: $default_color;
$h_scheme02_social_icon_link: #cdcdcd;
$h_scheme02_social_icon_link_hover: $default_color2;
$h_scheme02_menu_desctope_link_text: $default_color2;
$h_scheme02_menu_desctope_link_bg: transparent;
$h_scheme02_menu_desctope_link_text_hover: $default_color;
$h_scheme02_menu_desctope_link_bg_hover: transparent;
$h_scheme02_menu_desctope_link_text_active: $h_scheme02_menu_desctope_link_text_hover;
$h_scheme02_menu_desctope_link_bg_active: $h_scheme02_menu_desctope_link_bg_hover;
$h_scheme02_menu_desctope_link_text_selected: $h_scheme02_menu_desctope_link_text_hover;
$h_scheme02_menu_desctope_link_bg_selected: $h_scheme02_menu_desctope_link_bg_hover;
$h_scheme02_menu_desctope_hover_02_link_text: $default_color2;
$h_scheme02_menu_desctope_hover_02_link_bg: transparent;
$h_scheme02_menu_desctope_hover_02_link_text_hover: #ffffff;
$h_scheme02_menu_desctope_hover_02_link_bg_hover: $default_color;
$h_scheme02_menu_desctope_hover_02_link_text_active: $h_scheme02_menu_desctope_hover_02_link_text_hover;
$h_scheme02_menu_desctope_hover_02_link_bg_active: $h_scheme02_menu_desctope_hover_02_link_bg_hover;
$h_scheme02_menu_desctope_hover_02_link_text_selected: $h_scheme02_menu_desctope_hover_02_link_text_hover;
$h_scheme02_menu_desctope_hover_02_link_bg_selected: $h_scheme02_menu_desctope_hover_02_link_bg_hover;
$h_scheme02_dropdown_toggle_btn_text: $default_color2;
$h_scheme02_dropdown_toggle_btn_text_hover: $default_color;
$h_scheme03_bg: transparent;
$h_scheme03_text: #888888;
$h_scheme03_menu_desctope_link_text: #ffffff;
$h_scheme03_menu_desctope_link_bg: transparent;
$h_scheme03_menu_desctope_link_text_hover: $default_color;
$h_scheme03_menu_desctope_link_bg_hover: transparent;
$h_scheme03_menu_desctope_link_text_active: $h_scheme03_menu_desctope_link_text_hover;
$h_scheme03_menu_desctope_link_bg_active: $h_scheme03_menu_desctope_link_bg_hover;
$h_scheme03_menu_desctope_link_text_selected: $h_scheme03_menu_desctope_link_text_hover;
$h_scheme03_menu_desctope_link_bg_selected: $h_scheme03_menu_desctope_link_bg_hover;
$h_scheme03_menu_desctope_hover_02_link_text: #ffffff;
$h_scheme03_menu_desctope_hover_02_link_bg: transparent;
$h_scheme03_menu_desctope_hover_02_link_text_hover: #ffffff;
$h_scheme03_menu_desctope_hover_02_link_bg_hover: $default_color;
$h_scheme03_menu_desctope_hover_02_link_text_active: $h_scheme03_menu_desctope_hover_02_link_text_hover;
$h_scheme03_menu_desctope_hover_02_link_bg_active: $h_scheme03_menu_desctope_hover_02_link_bg_hover;
$h_scheme03_menu_desctope_hover_02_link_text_selected: $h_scheme03_menu_desctope_hover_02_link_text_hover;
$h_scheme03_menu_desctope_hover_02_link_bg_selected: $h_scheme03_menu_desctope_hover_02_link_bg_hover;
$h_scheme03_dropdown_toggle_btn_text: #ffffff;
$h_scheme03_dropdown_toggle_btn_text_hover: $default_color;
$header_mobile_separator: $border;
$header_mobile_menu_toggle_icon: $default_color2;
$header_mobile_menu_toggle_icon_hover: $default_color;
$header_mobile_stuck_bg: #ffffff;
$mobile_menu_bg: #ffffff;
$mobile_menu_fullscreen_bg: rgba(0, 0, 0, 0.55);
$mobile_menu_btn_text: $default_text_color;
$mobile_menu_btn_text_hover: $default_color;
$mobile_menu_btn_bg: transparent;
$mobile_menu_btn_bg_hover: transparent;
$mobile_menu_btn_icon: $mobile_menu_btn_text;
$mobile_menu_btn_icon_hover: $mobile_menu_btn_text_hover;
$mobile_menu_btn_separator: $border;
$mobile_menu_close_text: $mobile_menu_btn_text;
$mobile_menu_close_text_hover: $mobile_menu_btn_text_hover;
$mobile_menu_close_bg: $mobile_menu_btn_bg;
$mobile_menu_close_bg_hover: $mobile_menu_btn_bg_hover;
$mobile_menu_close_icon: $mobile_menu_btn_icon;
$mobile_menu_close_icon_hover: $mobile_menu_btn_icon_hover;
$mobile_menu_close_separator: $mobile_menu_btn_separator;
$mobile_menu_prev_text: $mobile_menu_btn_text;
$mobile_menu_prev_text_hover: $mobile_menu_btn_text_hover;
$mobile_menu_prev_bg: $mobile_menu_btn_bg;
$mobile_menu_prev_bg_hover: $mobile_menu_btn_bg_hover;
$mobile_menu_prev_icon: $mobile_menu_btn_icon;
$mobile_menu_prev_icon_hover: $mobile_menu_btn_icon_hover;
$mobile_menu_prev_separator: $mobile_menu_btn_separator;
$mobile_menu_next_text: $default_color2;
$mobile_menu_next_text_hover: $mobile_menu_btn_text_hover;
$mobile_menu_next_bg: $mobile_menu_btn_bg;
$mobile_menu_next_bg_hover: $mobile_menu_btn_bg_hover;
$mobile_menu_next_icon: $mobile_menu_btn_icon;
$mobile_menu_next_icon_hover: $mobile_menu_btn_icon_hover;
$mobile_menu_next_separator: $mobile_menu_btn_separator;
$mobile_menu_original_link_text: $default_color2;
$mobile_menu_original_link_text_hover: $mobile_menu_btn_text_hover;
$mobile_menu_original_link_bg: $mobile_menu_btn_bg;
$mobile_menu_original_link_bg_hover: $mobile_menu_btn_bg_hover;
$mobile_menu_original_link_icon: $mobile_menu_btn_icon;
$mobile_menu_original_link_icon_hover: $mobile_menu_btn_icon_hover;
$mobile_menu_original_link_separator: $mobile_menu_btn_separator;
$mobile_menu_link: $default_color2;
$mobile_menu_link_hover: $default_color;
$mobile_menu_link_bg: transparent;
$mobile_menu_link_bg_hover: transparent;
$mobile_menu_link_level_01: $mobile_menu_link;
$mobile_menu_link_level_01_hover: $mobile_menu_link_hover;
$mobile_menu_link_level_01_bg: $mobile_menu_link_bg;
$mobile_menu_link_level_01__bg_hover: $mobile_menu_link_bg_hover;
$mobile_menu_link_level_01_icon: $mobile_menu_link_level_01;
$mobile_menu_link_level_01_icon_hover: $mobile_menu_link_level_01_hover;
$mobile_menu_badge_text: #ffffff;
$mobile_menu_badge_text_new: $mobile_menu_badge_text;
$mobile_menu_badge_bg_new: #1393f5;
$mobile_menu_badge_text_fatured: $mobile_menu_badge_text;
$mobile_menu_badge_bg_fatured: #fdbc20;
$mobile_menu_badge_text_sale: $mobile_menu_badge_text;
$mobile_menu_badge_bg_sale: #f8353e;
/*
	Footer
*/
$f_newsletter_errors_bg: #f8353e;
$f_newsletter_errors_text: #ffffff;
$f_newsletter_errors_icon: $f_newsletter_errors_text;
$f_scheme01_text: $default_text_color;
$f_scheme01_link: $default_color;
$f_scheme01_link_hover: $default_color2;
$f_scheme01_bg: #f7f8fa;
$f_scheme01_title_text: $default_title_color;
$f_scheme01_title_text_hover: $default_color;
$f_scheme01_list_text: $default_text_color;
$f_scheme01_list_text_hover: $default_color;
$f_scheme01_address_title: $default_title_color;
$f_scheme01_newsletter_input_text: $default_text_color;
$f_scheme01_newsletter_input_text_focus: $f_scheme01_newsletter_input_text;
$f_scheme01_newsletter_input_bg: #ffffff;
$f_scheme01_newsletter_input_bg_focus: #ffffff;
$f_scheme01_newsletter_input_border: #ffffff;
$f_scheme01_newsletter_input_border_focus: $default_color;
$f_scheme01_newsletter_input_placeholder: $default_text_color;
$f_scheme01_newsletter_btn_text: #ffffff;
$f_scheme01_newsletter_btn_text_hover: #ffffff;
$f_scheme01_newsletter_btn_bg: $default_color;
$f_scheme01_newsletter_btn_bg_hover: #000000;
$f_scheme01_social_icon: #d8d8d8;
$f_scheme01_social_icon_hover: $default_color2;
$f_scheme02_text: #ffffff;
$f_scheme02_link: #ffffff;
$f_scheme02_link_hover: $default_color2;
$f_scheme02_bg: $default_color;
$f_scheme02_title_text: #ffffff;
$f_scheme02_title_text_hover: $default_color2;
$f_scheme02_list_text: #ffffff;
$f_scheme02_list_text_hover: $default_color2;
$f_scheme02_address_title: $default_title_color;
$f_scheme02_newsletter_input_text: $default_text_color;
$f_scheme02_newsletter_input_text_focus: $f_scheme02_newsletter_input_text;
$f_scheme02_newsletter_input_bg: #ffffff;
$f_scheme02_newsletter_input_bg_focus: #ffffff;
$f_scheme02_newsletter_input_border: #ffffff;
$f_scheme02_newsletter_input_border_focus: $default_color2;
$f_scheme02_newsletter_input_placeholder: $default_text_color;
$f_scheme02_newsletter_btn_text: #ffffff;
$f_scheme02_newsletter_btn_text_hover: #ffffff;
$f_scheme02_newsletter_btn_bg: #303030;
$f_scheme02_newsletter_btn_bg_hover: #000000;
$f_scheme02_social_icon: #ffffff;
$f_scheme02_social_icon_hover: #1c302d;
$f_scheme03_text: #888888;
$f_scheme03_link: $default_color;
$f_scheme03_link_hover: #ffffff;
$f_scheme03_bg: #303030;
$f_scheme03_title_text: #ffffff;
$f_scheme03_title_text_hover: $default_color2;
$f_scheme03_list_text: #888888;
$f_scheme03_list_text_hover: $default_color;
$f_scheme03_address_title: #ffffff;
$f_scheme03_newsletter_input_text: $default_text_color;
$f_scheme03_newsletter_input_text_focus: $f_scheme03_newsletter_input_text;
$f_scheme03_newsletter_input_bg: #ffffff;
$f_scheme03_newsletter_input_bg_focus: #ffffff;
$f_scheme03_newsletter_input_border: #ffffff;
$f_scheme03_newsletter_input_border_focus: $default_color;
$f_scheme03_newsletter_input_placeholder: $default_text_color;
$f_scheme03_newsletter_btn_text: #ffffff;
$f_scheme03_newsletter_btn_text_hover: #ffffff;
$f_scheme03_newsletter_btn_bg: $default_color;
$f_scheme03_newsletter_btn_bg_hover: #000000;
$f_scheme03_social_icon: $default_text_color;
$f_scheme03_social_icon_hover: #ffffff;
$f_scheme03_copyright_text: #888888;
$f_scheme04_text: #888888;
$f_scheme04_link: #888888;
$f_scheme04_link_hover: #ffffff;
$f_scheme04_bg: #222222;
$f_scheme04_title_text: #ffffff;
$f_scheme04_title_text_hover: $default_color2;
$f_scheme04_list_text: #888888;
$f_scheme04_list_text_hover: $default_color;
$f_scheme04_address_title: #ffffff;
$f_scheme04_newsletter_input_text: #ffffff;
$f_scheme04_newsletter_input_text_focus: $f_scheme04_newsletter_input_text;
$f_scheme04_newsletter_input_bg: #ffffff;
$f_scheme04_newsletter_input_bg_focus: #ffffff;
$f_scheme04_newsletter_input_border: #ffffff;
$f_scheme04_newsletter_input_border_focus: $default_color;
$f_scheme04_newsletter_input_placeholder: $default_text_color;
$f_scheme04_newsletter_btn_text: #ffffff;
$f_scheme04_newsletter_btn_text_hover: #ffffff;
$f_scheme04_newsletter_btn_bg: $default_color;
$f_scheme04_newsletter_btn_bg_hover: #000000;
$f_scheme04_social_icon: $default_text_color;
$f_scheme04_social_icon_hover: #ffffff;
$f_scheme04_copyright_text: #888888;
$f_scheme04_logo_text: #ffffff;
$f_mobile_bg: #f7f8fa;
$f_mobile_text: #888888;
$f_mobile_bottom_bg: #ffffff;
$f_mobile_bottom_text: #888888;
$f_mobile_collapse_title_text: $default_color2;
$f_mobile_collapse_title_text_hover: $default_color;
$f_mobile_collapse_title_icon: $default_text_color;
$f_mobile_collaps_newsletter_input_text: $default_text_color;
$f_mobile_collaps_newsletter_input_text_focus: $f_mobile_collaps_newsletter_input_text;
$f_mobile_collaps_newsletter_input_bg: #ffffff;
$f_mobile_collaps_newsletter_input_bg_focus: #ffffff;
$f_mobile_collaps_newsletter_input_border: #ffffff;
$f_mobile_collaps_newsletter_input_border_focus: $default_color;
$f_mobile_collaps_newsletter_input_placeholder: $default_text_color;
$f_mobile_collaps_newsletter_btn_text: #ffffff;
$f_mobile_collaps_newsletter_btn_text_hover: #ffffff;
$f_mobile_collaps_newsletter_btn_bg: $default_color;
$f_mobile_collaps_newsletter_btn_bg_hover: #000000;
$f_mobile_collaps_list_text: #888888;
$f_mobile_collaps_list_text_hover: $default_color;
$f_mobile_collaps_address_link: $default_color;
$f_mobile_collaps_address_link_hover: $default_color2;
$f_mobile_collaps_address_title: $default_color2;
$f_mobile_collaps_social_icon: $default_text_color;
$f_mobile_collaps_social_icon_hover: $default_color2;
$f_mobile_logo_text: $default_color;

$f_mobile_dark_bg: #303030;
$f_mobile_dark_bottom_bg: #222222;
$f_mobile_dark_text: #888888;
$f_mobile_dark_collapse_title_text: #ffffff;
$f_mobile_dark_collapse_border: rgba(233, 231, 231, 0.14);
/*
	Product item
*/
// product desktop
$product_label_text: #ffffff;
$product_label_new_text: $product_label_text;
$product_label_new_bg: #03a9f5;
$product_label_sale_text: $product_label_text;
$product_label_sale_bg: #f8353e;
$product_label_fatured_text: $product_label_text;
$product_label_fatured_bg: #fdbc20;
$product_label_stock_text: $product_label_text;
$product_label_stock_bg: $default_color2;
$product_label_in_stock_text: $product_label_text;
$product_label_in_stock_bg: #0ec482;
$product_description_bg: $default_bg_color;
$product_add_info_text: #999999;
$product_add_info_text_hover: $default_color;
$product_title_text: $default_title_color;
$product_title_text_hover: $default_color;
$product_price_text: $default_color2;
$product_new_price_text: #f8353e;
$product_old_price_text: $product_price_text;
$product_btn_addtocart_text: $default_color;
$product_btn_addtocart_text_hover: $default_color2;
$product_btn_addtocart_icon: $product_btn_addtocart_text;
$product_btn_addtocart_icon_hover: $product_btn_addtocart_text_hover;
$product_btn02_addtocart_text: #ffffff;
$product_btn02_addtocart_bg: $default_color;
$product_btn02_addtocart_icon: #ffffff;
$product_btn02_addtocart_bg_hover: #2267d8;
$product_btn02_addtocart_text_hover: #ffffff;
$product_btn02_addtocart_icon_hover: #ffffff;

$product_btn_quickview_text: $default_color2;
$product_btn_quickview_text_hover: #ffffff;
$product_btn_quickview_view_text_hover: $default_color;
$product_btn_quickview_bg: #ffffff;
$product_btn_quickview_bg_hover: $default_color;

$product_btn_wishlist_text: $default_color2;
$product_btn_wishlist_text_hover: #ffffff;
$product_btn_wishlist_text_active: #ffffff;
$product_btn_wishlist_view_text_hover: $default_color;
$product_btn_wishlist_view_text_active: $default_color;
$product_btn_wishlist_bg: #ffffff;
$product_btn_wishlist_bg_hover: $default_color;
$product_btn_wishlist_bg_active: $default_color;

$product_btn_compare_text: $default_color2;
$product_btn_compare_text_hover: #ffffff;
$product_btn_compare_text_active: #ffffff;
$product_btn_compare_view_text_hover: $default_color;
$product_btn_compare_view_text_active: $default_color;
$product_btn_compare_bg: #ffffff;
$product_btn_compare_bg_hover: $default_color;
$product_btn_compare_bg_active: $default_color;

$product_select_bg: #f7f8fa;
$product_select_text: #777777;
// product desktop design02
$product_design02_label_text: #ffffff;
$product_design02_label_new_text: $product_design02_label_text;
$product_design02_label_new_bg: #1393f5;
$product_design02_label_sale_text: $product_design02_label_text;
$product_design02_label_sale_bg: #f8353e;
$product_design02_label_fatured_text: $product_design02_label_text;
$product_design02_label_fatured_bg: #fdbc20;
$product_design02_label_stock_text: $product_design02_label_text;
$product_design02_label_stock_bg: $default_color2;
$product_design02_bg: #f7f8fa;
$product_design02_add_info_text: $default_color2;
$product_design02_add_info_text_hover: $default_color;
$product_design02_title_text: $default_color2;
$product_design02_title_text_hover: $default_color;
$product_design02_price_text: $default_color2;
$product_design02_new_price_text: #f8353e;
$product_design02_old_price_text: $product_design02_price_text;
$product_design02_btn_addtocart_text: $default_color;
$product_design02_btn_addtocart_text_hover: $default_color2;
$product_design02_btn_addtocart_icon: $product_design02_btn_addtocart_text;
$product_design02_btn_addtocart_icon_hover: $product_design02_btn_addtocart_text_hover;
$product_design02_btn_quickview_text: $default_color2;
$product_design02_btn_quickview_text_hover: $default_color;
$product_design02_btn_quickview_text_active: $default_color;
$product_design02_btn_wishlist_text: $default_color2;
$product_design02_btn_wishlist_text_hover: $default_color;
$product_design02_btn_wishlist_text_active: $default_color;
$product_design02_btn_compare_text: $default_color2;
$product_design02_btn_compare_text_hover: $default_color;
$product_design02_btn_compare_text_active: $default_color;

$product_design02_btn_link: $default_color2;
$product_design02_btn_link_hover: $default_color;
//options swatch design02
$product_design02_swatch_bg: #ffffff;
$product_design02_swatch_bg_hover: $default_color;
$product_design02_swatch_bg_active: $default_color;
$product_design02_swatch_text: $default_color2;
$product_design02_swatch_text_hover: #ffffff;
$product_design02_swatch_text_active: #ffffff;
$product_design02_swatch_hover_border01: #ffffff;
$product_design02_swatch_active_border01: $product_design02_swatch_hover_border01;
//options swatch
$tt_options_swatch_bg: #f7f8fa;
$tt_options_swatch_bg_hover: $default_color;
$tt_options_swatch_text: $default_text_color;
$tt_options_swatch_text_hover: #ffffff;
$tt_options_swatch_border: #e9e7e6; //if coincides bg
$tt_options_swatch_hover_border01: #ffffff;
$tt_options_swatch_hover_border02: $default_color;
// product mobile
$product_mobile_label_text: #ffffff;
$product_mobile_label_new_text: $product_mobile_label_text;
$product_mobile_label_new_bg: #1393f5;
$product_mobile_label_sale_text: $product_mobile_label_text;
$product_mobile_label_sale_bg: #f8353e;
$product_mobile_label_fatured_text: $product_mobile_label_text;
$product_mobile_label_fatured_bg: #fdbc20;
$product_mobile_label_stock_text: $product_mobile_label_text;
$product_mobile_label_stock_bg: $default_color2;
$product_mobile_description_bg: $default_bg_color;
$product_mobile_add_info_text: #999999;
$product_mobile_add_info_text_hover: $default_color;
$product_mobile_title_text: $default_title_color;
$product_mobile_title_text_hover: $default_color;
$product_mobile_price_text: $default_color2;
$product_mobile_new_price_text: #f8353e;
$product_mobile_old_price_text: $product_mobile_price_text;
$product_mobile_btn_addtocart_text: #ffffff;
$product_mobile_btn_addtocart_text_hover: #ffffff;
$product_mobile_btn_addtocart_bg: $default_color;
$product_mobile_btn_addtocart_bg_hover: #2267d8;
$product_mobile_btn_addtocart_icon: $product_mobile_btn_addtocart_text;
$product_mobile_btn_addtocart_icon_hover: $product_mobile_btn_addtocart_text_hover;
$product_mobile_btn_quickview_text: $default_color2;
$product_mobile_btn_quickview_text_active: $default_color;

$product_mobile_btn_wishlist_text: $default_color2;
$product_mobile_btn_wishlist_text_active: $default_color;

$product_mobile_btn_compare_text: $default_color2;
$product_mobile_btn_compare_text_active: $default_color;
//countdown
$countdown_section_bg: rgba(255, 255, 255, 0.9);
$countdown_section_text: $default_color;
/*
	Product listing product
*/
/*Filters options*/
$listing_filter_btn_toggle_text: $default_color;
$listing_filter_btn_toggle_text_hover: $default_color2;
$listing_filter_btn_toggle_icon: $listing_filter_btn_toggle_text;
$listing_filter_btn_toggle_icon_hover: $listing_filter_btn_toggle_text_hover;
$listing_filter_sort_select_text: $default_text_color;
$listing_filter_sort_select_text_hover: $default_color2;
$listing_filter_sort_btn_icon: $default_color2;
$listing_filter_modal_bg: rgba(0, 0, 0, 0.5);
$listing_filter_btn_close_desctope_bg: #ffffff;
$listing_filter_btn_close_desctope_text: $default_color2;
$listing_filter_btn_close_desctope_text_hover: $default_color;
$listing_filter_btn_close_desctope_icon: $default_color2;
$listing_filter_btn_close_desctope_icon_hover: $default_color2;
$listing_filter_btn_close_mobile_text: $default_text_color;
$listing_filter_btn_close_mobile_text_hover: $default_color;
$listing_filter_btn_close_mobile_bg: #ffffff;
$listing_filter_btn_close_mobile_border: $border;
$listing_filter_btn_close_mobile_icon: $default_color2;
$listing_filter_btn_close_mobile_icon_hover: $default_color2;
$listing_filter_btn_close_gradient_bg01: rgba(255, 255, 255, 0);
$listing_filter_btn_close_gradient_bg02: rgba(255, 255, 255, 1);
$listing_filter_col_bg_desctope: #ffffff;
$listing_filter_col_bg_mobile: #ffffff;
$listing_aside_collapse_title_text: $default_title_color;
$listing_aside_collapse_title_text_hover: $default_color;
$listing_aside_collapse_title_border: $border;
$listing_aside_collapse_title_icon: $listing_aside_collapse_title_text;
$listing_aside_collapse_title_icon_hover: $listing_aside_collapse_title_icon;
$listing_aside_list_text: $default_text_color;
$listing_aside_list_text_hover: $default_color;
$listing_aside_list_text_active: $default_color;
$listing_aside_list_icon: $default_color2;
$listing_aside_list_icon_hover: $listing_aside_list_icon;
$listing_aside_list_icon_active: $listing_aside_list_text_active;
$list_row_text: $default_text_color;
$list_row_text_hover: $default_color;
$list_row_text_active: $list_row_text_hover;
$listing_aside_img_block_title: $default_title_color;
$listing_aside_img_block_title_hover: $default_color;
$listing_aside_img_block_price: $default_color2;
$listing_aside_img_block_price_new: #f8353e;
$listing_aside_img_block_price_old: $listing_aside_img_block_price;
$listing_aside_mobile_filter_select_text: $default_text_color;
$listing_aside_mobile_filter_select_text_hover: $default_color2;
$listing_aside_mobile_filter_select_bg: #f7f8fa;
/*
	rating
*/
$rating_icon: #ffb503;
$rating_icon_half: $rating_icon;
$rating_icon_empty: #d4d4d4;
/*
	label
*/
$label_new_text: #ffffff;
$label_new_bg: #1393f5;
$label_sale_text: #ffffff;
$label_sale_bg: #f8353e;
$label_out_stock_text: #ffffff;
$label_out_stock_bg: $default_color2;
$label_our_fatured_text: #ffffff;
$label_our_fatured_bg: #fdbc20;
/*
	Product single
*/
$product_single_title_text: $default_title_color;
$product_single_title_options_text: $product_single_title_text;
$product_single_title_price: $default_color;
$product_single_title_price_old: $default_color;
$product_single_title_price_sale: #f8353e;
$product_single_review_link: $default_color;
$product_single_review_link_hover: $default_color2;
$product_single_rating_icon: $rating_icon;
$product_single_rating_icon_half: $rating_icon_half;
$product_single_rating_icon_empty: $rating_icon_empty;
$product_single_input_counter_text: $default_color2;
$product_single_input_counter_bg: #f7f8fa;
$product_single_input_counter_btn: $default_text_color;
$product_single_input_counter_btn_hover: $default_color2;
$product_single_information_btn_text: $default_color;
$product_single_information_btn_text_hover: $default_color2;
$product_single_countdown_bg: rgba(247, 248, 250, 1);
$product_single_countdown_text: $default_color2;
$product_single_zoom_icon: $default_color2;
$product_single_zoom_icon_hover: #ffffff;
$product_single_zoom_bg: #ffffff;
$product_single_zoom_bg_hover: $default_color;
/* Collapse block */
$tt_collapse_block_title: #999999;
$tt_collapse_block_title_hover: $default_color2;
$tt_collapse_block_title_active: $tt_collapse_block_title_hover;
$tt_collapse_block_separator: $border;
/* Review-block */
$review_block_title: $default_title_color;
$review_block_link: $default_color;
$review_block_avatar_bg: #f7f8fa;
$review_block_avatar_icon: $default_color2;
$review_block_comments_info_text_color01: $default_text_color;
$review_block_comments_info_text_color02: $default_color2;
$review_block_comments_title: $default_title_color;
$review_block_message_info_text_color01: $default_color2;
$review_block_message_info_text_color02: $default_color;
$review_block_indicator_title: $default_title_color;
/* produc slider vertical (product.html)*/
$produc_slider_vertical_active_img: #ffffff;
/* produc slider vertical (product-02.html)*/
$produc_slider_img_active_img: #ffffff;
/* video link*/
$video_link_icon: $default_color;
$video_link_icon_hover: $default_color2;
/*
	Blog
*/
$blog_tag_text: $default_color;
$blog_tag_text_hover: $default_color2;
$blog_title_text: $default_title_color;
$blog_title_text_hover: $default_color;
$blog_meta_text: #999999;
$blog_meta_text_color2: $default_color2;
$blog_meta_comments_text: $blog_meta_text;
$blog_meta_comments_text_hover: $default_color;
$blog_meta_comments_icon: $default_color2;
$blog_meta_comments_icon_hover: $default_color;
$blog_post_video_icon: #ffffff;
$blog_post_video_icon_hover: $default_color;
$box_link_bg: #f7f8fa;
$box_link_bg_hover: #222222;
$box_link_text: $default_color;
$box_link_text_hover: #ffffff;
$blog_post_single_tag_text: $blog_tag_text;
$blog_post_single_tag_text_hover: $blog_tag_text_hover;
$blog_post_single_meta_text: $blog_meta_text;
$blog_post_single_meta_text_color2: $blog_meta_text_color2;
$blog_post_single_meta_tag_text: $blog_tag_text;
$blog_post_single_meta_tag_text_hover: $blog_tag_text_hover;
$blog_comments_avatar_icon: $default_color2;
$blog_comments_avatar_bg: #f7f8fa;
$blog_comments_title_text: $default_text_color;
$blog_comments_title_text_color2: $default_color2;
$blog_comments_btn: $default_color;
$blog_comments_btn_hover: $default_color2;
$blog_comments_separator: $border;
$blog_thumb_description_wrapper: $default_bg_color;
$blog_thumb_description_text: $default_text_color;
$blog_thumb_description_tag: $blog_tag_text;
$blog_thumb_description_tag_hover: $blog_tag_text_hover;
$blog_thumb_description_title: $blog_title_text;
$blog_thumb_description_title_hover: $blog_title_text_hover;
$blog_thumb_description_meta_text: $blog_meta_text;
$blog_thumb_description_meta_text_color2: $blog_meta_text_color2;
$blog_thumb_description_comments_text: $blog_meta_comments_text;
$blog_thumb_description_comments_text_hover: $blog_meta_comments_text_hover;
$blog_thumb_description_comments_icon: $blog_meta_comments_icon;
$blog_thumb_description_comments_icon_hover: $blog_meta_comments_icon_hover;
// left col blog
$block_aside_separator: $border;
$tt_form_search_bg: $default_bg_color;
$tt_form_search_icon: $default_color2;
$tt_form_search_icon_hover: $default_color;
$tt_list_inline_text: $default_text_color;
$tt_list_inline_text_hover: $default_color;
$tt_pagination_link: $default_color2;
$tt_pagination_link_hover: $default_color;
$tt_pagination_link_active: $tt_pagination_link_hover;
$tt_pagination_icon: $tt_pagination_link;
$tt_pagination_icon_hover: $tt_pagination_link_hover;
// blog colums
$blog_colums_post_video_icon: $blog_post_video_icon;
$blog_colums_post_video_icon_hover: $blog_post_video_icon_hover;
$blog_colums_post_content_bg: $default_bg_color;
$blog_colums_post_content_text: $default_text_color;
$blog_colums_post_tag_text: $blog_tag_text;
$blog_colums_post_tag_text_hover: $blog_tag_text_hover;
$blog_colums_post_title_text: $blog_title_text;
$blog_colums_post_title_text_hover: $blog_title_text_hover;
$blog_colums_post_meta_text: $blog_meta_text;
$blog_colums_post_meta_text_color2: $blog_meta_text_color2;
$blog_colums_post_meta_comments_text: $blog_colums_post_meta_text;
$blog_colums_post_meta_comments_text_hover: $blog_meta_comments_text_hover;
$blog_colums_post_meta_comments_icon: $blog_meta_comments_icon;
$blog_colums_post_meta_comments_icon_hover: $blog_meta_comments_icon_hover;
// blog filter
$blog_filter_btn_text: #999999;
$blog_filter_btn_text_hover: $default_color2;
$blog_filter_btn_text_active: $blog_filter_btn_text_hover;
// blog aside
$blog_aside_post_tag_text: $default_color;
$blog_aside_post_tag_text_hover: $default_color2;
$blog_aside_post_title: $default_title_color;
$blog_aside_post_title_hover: $default_color;
$blog_aside_post_link: $default_text_color;
$blog_aside_post_link_hover: $default_text_color;
$blog_aside_post_info_text: $default_text_color;
$blog_aside_post_info_text_color2: $default_color2;
/*
Instagram
*/
$instagram_icon: #ffffff;
$instagram_bg: rgba(0, 0, 0, 0.7);
/*
Lookbook
*/
$lookbook_hotspot_icon: #ffffff;
$lookbook_hotspot_border: rgba(255, 255, 255, 0.8);
$lookbook_hotspot_bg: $default_color;
$lookbook_hotspot_bg_hover: $default_color2;
$lookbook_hotspot_bg_active: $default_color2;
$lookbook_hotspot_btn_close_text: $default_color2;
$lookbook_hotspot_btn_close_text_hover: $default_color;
$lookbook_description_bg: #ffffff;
$lookbook_description_shadow: rgba(0, 0, 0, 0.08);
$lookbook_description_title_text: $default_title_color;
$lookbook_description_title_text_hover: $default_color;
$lookbook_description_price: $default_color2;
$lookbook_description_price_new: #f8353e;
$lookbook_description_price_old: $lookbook_description_price;
$lookbook_mobile_bg_item: rgba(255, 255, 255, 0.8);
/*
Modal
*/
$modal_btn_close_icon: #ffffff;
$modal_btn_close_icon_hover: $default_color;
$modal_bg: #ffffff;
$modal_backdrop: #000000;
/*addtocart*/
$modal_addtocart_desctope_messages_text: $default_color2;
$modal_addtocart_desctope_messages_icon: $default_color;
$modal_addtocart_desctope_title: $default_title_color;
$modal_addtocart_desctope_title_hover: $default_color;
$modal_addtocart_desctope_qty_text: $default_color2;
$modal_addtocart_desctope_total_text: $default_color2;
$modal_addtocart_desctope_separator: $border;
$modal_addtocart_mobile_messages_text: $default_color2;
$modal_addtocart_mobile_messages_icon: $default_color;
$modal_addtocart_cart_total_text: $default_text_color;
$modal_addtocart_cart_total_title: $default_title_color;
$modal_addtocart_cart_total_price: $default_color;
/*newsletter*/
$modal_newsletter_title_small: #ffffff;
$modal_newsletter_title_large: $default_color;
$modal_newsletter_text_color01: #ffffff;
$modal_newsletter_text_color02: $default_text_color;
$modal_newsletter_social_icon: #d8d8d8;
$modal_newsletter_social_icon_hover: $default_color;
$modal_newsletter_checkbox_text: #ffffff;
$modal_newsletter_checkbox_label: $modal_newsletter_checkbox_text;
$modal_newsletter_checkbox_label_hover: $default_color;
/*layout product info (product-variable.html)*/
$modal_porduct_info_title: $default_title_color;
$modal_porduct_info_table_thead_text: $default_text_color;
$modal_porduct_info_table_td_text: $default_text_color;
$modal_porduct_info_table_tr_bg: #f7f8fa;
$modal_porduct_info_02_title: $default_title_color;
$modal_porduct_info_02_list_text: $default_text_color;
$modal_porduct_info_02_list_text_hover: $default_color;
$modal_porduct_info_02_list_marker: $default_text_color;
/*subsribe_good*/
$modal_subsribe_good_text: $default_color2;
$modal_subsribe_good_icon: $default_color;
/*
Portfolio
*/
$portfolio_nav_btn_text: #999999;
$portfolio_nav_btn_text_hover: $default_color2;
$portfolio_nav_btn_text_active: $portfolio_nav_btn_text_hover;
$portfolio_item_bg: rgba(0, 0, 0, 0.7);
$portfolio_item_text: #ffffff;
$portfolio_item_icon: #ffffff;
$portfolio_item_icon_hover: $default_color;
$portfolio_item_title: #ffffff;
$portfolio_item_title_hover: $default_color;
/*
	magnific popup (portfolio pages)
*/
$magnific_popup_bg: rgba(0, 0, 0, 0.5);
$magnific_popup_shadow: rgba(0, 0, 0, 0.3);
$magnific_popup_arrow_icon: #ffffff;
$magnific_popup_arrow_icon_hover: $default_color;
$magnific_popup_bt_close: #ffffff;
$magnific_popup_bt_close_hover: $default_color;
$magnific_popup_counter: #ffffff;
/*
Promo
*/
$promo_bg_content: rgba(255, 255, 255, 0.9);
$promo_hover02_bg_content_hover: rgba($default_color, 0.9);
$promo_hover02_text_content_hover: #ffffff;
$promo_hover02_hover_bt_underline_border: transparent;
$promo_hover_bt_underline_border: transparent;
$promo_hover_bt_underline_text: $default_color;
$promo_text: $default_text_color;
$promo_title_large_text: $default_title_color;
$promo_title_large_text_hover: $default_title_color;
$promo_title_small_text: $default_title_color;
$promo_title_small_text_hover: $default_title_color;
/*promo full width (index-09.html)*/
$promo_fullwidth_text: $default_color2;
$promo_fullwidth_title_small: $default_title_color;
$promo_fullwidth_title_large: $default_title_color;
/*promo full width (index-13.html)*/
$promo_fullwidth02_text: $default_text_color;
$promo_fullwidth02_title_large: $default_title_color;
/*collection img (index-02.html)*/
$collection_item_title: $default_title_color;
$collection_item_title_hover: $default_color;
$collection_item_info_text: #999999;
/* promo fixed */
$promo_fixed_bg: #ffffff;
$promo_fixed_text: #999999;
$promo_fixed_link: $default_color2;
$promo_fixed_link_hover: $default_color;
$promo_fixed_text_info: #999999;
$promo_fixed_shadow: rgba(0, 0, 0, 0.08);
$promo_fixed_bt_close_icon: #999999;
$promo_fixed_bt_close_icon_hover: $default_color;
/*
	Shop cart
*/
/* shopping_cart_01.html */
$shopcart_border: $border;
$shopcart_border_box: $shopcart_border;
$shopcart_btn_close: $default_color2;
$shopcart_btn_close_hover: $default_color;
$shopcart_price: $default_color2;
$shopcart_title_text: $default_title_color;
$shopcart_title_text_hover: $default_color;
$shopcart_table01_text: $default_color2;
$shopcart_table01_total_price: $default_color;
/* shopping_cart_02.html */
$shopcart02_border: $border;
$shopcart02_btn_close: $default_color2;
$shopcart02_btn_close_hover: $default_color;
$shopcart02_price: $default_color2;
$shopcart02_title_text: $default_title_color;
$shopcart02_title_text_hover: $default_color;
$shopcart02_list_description_text: $default_text_color;
/*
	Shop account
*/
/* account_address.html, account.html, account_order.html */
$shopping_layout_title_text: $default_title_color;
$shopping_layout_btn_text: $default_color;
$shopping_layout_btn_text_hover: $default_color2;
$shopping_layout_border: $border;
$shopping_layout_table_thead_text: $default_color2;
$shopping_layout_table_link: $default_color;
$shopping_layout_table_link_hover: $default_color2;
$shopping_layout_info_title: $shopping_layout_title_text;
$shopping_layout_info_description_text_color02: $default_color;
$shopping_layout_info_description_link: $default_color;
$shopping_layout_info_description_link_hover: $default_color2;

/*
	slider revolution(index pages)
*/
$slider_revolution_nav_bullet_bg: #dadada;
$slider_revolution_nav_bullet_bg_active: $default_color2;
$slider_revolution_video_icon: #c2c2c2;
$slider_revolution_video_icon_hover: $default_color;
$slider_revolution_title: $default_title_color;
$slider_revolution_arrow: #dadada;
$slider_revolution_arrow_hover: $default_color2;
$slider_revolution_btn_bg: $btn_colorbg;
$slider_revolution_btn_bg_hover: $btn_colorbg_hover;
$slider_revolution_btn_text: $btn_colortext;
$slider_revolution_btn_text_hover: $btn_colortext_hover;
/*
	slider main slick(index-14.html)
*/
$slick_main_title: $default_title_color;
$slick_main_title_small: $slick_main_title;
$slick_main_title_large: $slick_main_title;
$slick_main_text: $default_color2;
$slick_main_arrow: #dadada;
$slick_main_arrow_hover: $default_color2;
$slick_main_bullet_bg: #dadada;
$slick_main_bullet_bg_hover: $default_color2;
$slick_main_bullet_bg_active: $slick_main_bullet_bg_hover;
/*
	slider slider
*/
$slick_default_arrow_bg: #f7f8fa;
$slick_default_arrow_text: $default_color2;
$slick_default_arrow_bg_hover: $default_color;
$slick_default_arrow_text_hover: #ffffff;
$slick_button_bg: #f7f8fa;
$slick_button_bg_hover: $default_color;
$slick_button_text: $default_color2;
$slick_button_text_hover: #ffffff;
$slick_quantity_text: #989898;
$slick_quantity_number: $default_color2;
$slick_button_vertical_text: $default_color2;
$slick_button_vertical_text_hover: #ffffff;
$slick_button_vertical_bg: #f7f8fa;
$slick_button_vertical_bg_hover: $default_color;
$slick_slider_dots_bg: #dadada;
$slick_slider_dots_bg_hover: $default_color2;
$slick_slider_dots_bg_active: $slick_slider_dots_bg_hover;
$slick_location01_arrow_bg: #ffffff;
$slick_location01_arrow_text: $default_color2;
$slick_location01_arrow_text_hover: #ffffff;
$slick_location01_arrow_bg_hover: $default_color;
$slick_location02_arrow_bg: #f7f8fa;
$slick_location02_arrow_bg_hover: $default_color;
$slick_location02_arrow_text: $default_color2;
$slick_location02_arrow_text_hover: #ffffff;
$slick_location03_arrow_bg: transparent;
$slick_location03_arrow_bg_hover: transparent;
$slick_location03_arrow_text: $default_color2;
$slick_location03_arrow_text_hover: $default_color;
$slick_locationTab_arrow_bg: #f7f8fa;
$slick_locationTab_arrow_bg_hover: $default_color;
$slick_locationTab_arrow_text: $default_color2;
$slick_locationTab_arrow_text_hover: #ffffff;
$slick_locationRightTop_arrow_bg: #f7f8fa;
$slick_locationRightTop_arrow_bg_hover: $default_color;
$slick_locationRightTop_arrow_text: $default_color2;
$slick_locationRightTop_arrow_text_hover: #ffffff;
$slick_locationCenter_arrow_bg: transparent;
$slick_locationCenter_arrow_bg_hover: transparent;
$slick_locationCenter_arrow_text: #dadada;
$slick_locationCenter_arrow_text_hover: $default_color2;
$slick_locationCenter02_arrow_bg: #f7f8fa;
$slick_locationCenter02_arrow_bg_hover: $default_color;
$slick_locationCenter02_arrow_text: $default_color2;
$slick_locationCenter02_arrow_text_hover: #ffffff;
$slick_type02_arrow_bg: #f7f8fa;
$slick_type02_arrow_bg_hover: #f7f8fa;
$slick_type02_arrow_text: #dadada;
$slick_type02_arrow_text_hover: $default_color2;

/*
	tooltip
*/
$tooltip_bg: #ffffff;
$tooltip_text: $default_color2;
$tooltip_shadow: rgba(0, 0, 0, 0.13);

/*---------------------------------------*/
/*--------   Table of contents   --------*/
/*
0. Componentes Edisa
1. External module
	1.1 Bootstrap
	1.2 Bootstrap datepicker
	1.3 Slider Slick
	1.4 Magnific popup
	1.5 Perfect scrollbar
	1.6 Slider Revolution
2. Fonts icons
3. Header
4. Footer
5. Blog pages
6. Portfolio pages
7. Product single pages
8. Modal
9. Listing product
10. Product item
11. Promo box
12. Promo box fixed
13. Shopping Cart pages
14. Lookbook module
15. Slider Revolution
16. Slider Slick
17. Extend module
18. Loader module
19. Boxed module
20. Instafeed module
21. The same product height
22. Tooltip
23. Wishlist page
24. Compare page
25. Menu categories
26. Typography
---------------------------------------*/

@import "module_mixin.scss";

/*---------------------------------------*/
/*-------   1. Componentes Edisa --------*/
/*---------------------------------------*/
@import "../componentes/ecommerce.scss";
/*---------------------------------------*/
/*--------   1. External module  --------*/
/*---------------------------------------*/
/*-------  1.1 Bootstrap  --------*/
@import "../external/bootstrap/scss/bootstrap.scss";
/*-------  1.2 Bootstrap datepicker  --------*/
@import "../external/bootstrap-datepicker/datepicker.scss";
/*-------  1.3 Slider Slick  --------*/
@import "../external/slick/slick.scss";
/*-------  1.4 Magnific popup  --------*/
@import "../external/magnific-popup/magnific-popup.scss";
/*-------  1.5 Perfect scrollbar  --------*/
@import "../external/perfect-scrollbar/perfect-scrollbar.scss";
/*-------  1.6 Slider Revolution  --------*/
@import "../external/rs-plugin/css/settings.scss";
/*---------------------------------------*/
/*--------     2. Fonts icons    --------*/
/*---------------------------------------*/
@import "../font/style.scss";
/*---------------------------------------*/
/*--------     3. Header         --------*/
/*---------------------------------------*/
@import "module_header.scss";
/*---------------------------------------*/
/*--------     4. Footer         --------*/
/*---------------------------------------*/
@import "module_footer.scss";
/*---------------------------------------*/
/*--------     5. Blog pages     --------*/
/*---------------------------------------*/
//@import "module_blog.scss";
/*---------------------------------------*/
/*--------     6. Portfolio pages  ------*/
/*---------------------------------------*/
//@import "module_portfolio.scss";
/*---------------------------------------*/
/*------   7. Product single pages   ----*/
/*---------------------------------------*/
@import "module_product_single.scss";
/*---------------------------------------*/
/*--------      8. Modal         --------*/
/*---------------------------------------*/
@import "module_modal.scss";
/*---------------------------------------*/
/*--------   9. Listing product   -------*/
/*---------------------------------------*/
@import "module_listing_product.scss";
/*---------------------------------------*/
/*--------  10. Product item     --------*/
/*---------------------------------------*/
@import "module_content_product_item.scss";
/*---------------------------------------*/
/*--------   11. Promo box       --------*/
/*---------------------------------------*/
//@import "module_promo.scss";
/*---------------------------------------*/
/*--------   12. Promo box fixed  -------*/
/*---------------------------------------*/
//@import "module_promo_fixed.scss";
/*---------------------------------------*/
/*-------  13. Shopping Cart pages  -----*/
/*---------------------------------------*/
@import "module_shopcart_page.scss";
/*---------------------------------------*/
/*-------  14. Lookbook module      -----*/
/*---------------------------------------*/
@import "module_lookbook.scss";
/*---------------------------------------*/
/*-------  15. Slider Revolution    -----*/
/*---------------------------------------*/
@import "module_slider_revolution.scss";
/*---------------------------------------*/
/*-------    16. Slider Slick       -----*/
/*---------------------------------------*/
@import "module_slider_slick.scss";
/*---------------------------------------*/
/*-------   17. Extend module       -----*/
/*---------------------------------------*/
@import "module_extend.scss";
/*---------------------------------------*/
/*-------   18. Loader module       -----*/
/*---------------------------------------*/
@import "module_loader.scss";
/*---------------------------------------*/
/*-------   19. Boxed module        -----*/
/*---------------------------------------*/
@import "module_boxed.scss";
/*---------------------------------------*/
/*-------  20. Instafeed module     -----*/
/*---------------------------------------*/
//@import "module_instafeed.scss";
/*---------------------------------------*/
/*----  21. The same product height  ----*/
/*---------------------------------------*/
//@import "module_option_product_height.scss";
/*---------------------------------------*/
/*----  22. Tooltip  ----*/
/*---------------------------------------*/
@import "module_tooltip.scss";
/*---------------------------------------*/
/*----  23. Wishlist page  ----*/
/*---------------------------------------*/
//@import "module_wishlist.scss";
/*---------------------------------------*/
/*----  24. Compare page  ----*/
/*---------------------------------------*/
//@import "module_compare.scss";
/*---------------------------------------*/
/*----  25. Menu categories  ----*/
/*---------------------------------------*/
//@import "module_menu_categories.scss";
/*---------------------------------------*/
/*-------     26. Typography        -----*/
/*---------------------------------------*/
html.touch-device {
  overflow-x: hidden;
}
body {
  font-family: $default_font;
  font-size: 14px;
  line-height: 22px;
  font-weight: 300;
  color: $default_text_color;
  background: $default_bg_color;
  margin: 0;
  overflow-x: hidden;
  direction: ltr;
  background-position: center 0px;
  background-repeat: no-repeat;
}
a {
  text-decoration: none;
  outline: none;
  color: $link_color_base;
}
a:hover {
  outline: none;
  text-decoration: none;
  outline: none;
}
a:active,
a:visited,
a:focus {
  text-decoration: none;
  outline: none;
}
.btn {
  background: $btn_colorbg;
  font-family: $default_font2;
  border: none;
  color: $btn_colortext;
  font-size: 14px;
  line-height: 1;
  font-weight: 400;
  letter-spacing: 0.03em;
  position: relative;
  outline: none;
  padding: 6px 31px 4px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 40px;
  cursor: pointer;
  @include border-radius($border_radius);
  @include animation();

  @media (max-width: 575px) {
    font-size: 12px;
    padding-left: 25px;
    padding-right: 25px;
    height: 39px;
  }
}
.btn:not(.tt-icon-right) {
  @media (min-width: 576px) {
    [class^="icon-"] {
      font-size: 20px;
      margin-right: 10px;
    }
  }
  @media (max-width: 575px) {
    [class^="icon-"] {
      font-size: 18px;
      margin-right: 10px;
    }
  }
}
.btn.tt-icon-right {
  @media (min-width: 576px) {
    [class^="icon-"] {
      font-size: 20px;
      margin-left: 10px;
      position: relative;
      top: -2px;
    }
  }
  @media (max-width: 575px) {
    [class^="icon-"] {
      font-size: 18px;
      margin-left: 10px;
    }
  }
}
.btn:active,
.btn:visited,
.btn:focus,
button:focus {
  outline: none;
  box-shadow: none;
}
.btn:hover {
  background: $btn_colorbg_hover;
  color: $btn_colortext_hover;
  outline: none;
}
.btn-lg {
  height: 50px;
  padding: 5px 31px 4px;
}
.btn-xl {
  @media (min-width: 1101px) {
    height: 50px;
    padding: 2px 41px 0px;
  }
  @media (min-width: 790px) and (max-width: 1100px) {
    height: 40px;
    padding-top: 2px;
    padding-bottom: 0;
  }
  @media (max-width: 789px) {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 2px;
    padding-bottom: 0;
  }
}
.btn:focus,
.btn.focus {
  box-shadow: none;
}
.btn.btn-white {
  background: $btnwhite_colorbg;
  color: $btnwhite_colortext;
}
.btn.btn-white:hover {
  background: $btnwhite_colorbg_hover;
  color: $btnwhite_colortext_hover;
}
.btn.btn-dark {
  background: $btndark_colorbg;
  color: $btndark_colortext;
}
.btn.btn-dark:hover {
  background: $btndark_colorbg_hover;
  color: $btndark_colortext_hover;
}
.btn.btn-border {
  background: $btnborder_colorbg;
  color: $btnborder_colortext;
  border: 2px solid $btnborder_colorborder;
  height: 40px;
  padding-left: 29px;
  padding-right: 29px;
}
.btn.btn-border:hover {
  background: $btnborder_colorbg_hover;
  color: $btnborder_colortext_hover;
  border-color: $btnborder_colorborder_hover;
}
.btn.btn-border01 {
  background: $btnborder01_colorbg;
  color: $btnborder01_colortext;
  border: 2px solid $btnborder01_colorborder;
  height: 36px;
  padding-left: 29px;
  padding-right: 29px;
  pointer-events: none;
  @include border-radius(6px);
}
.btn.btn-border01:hover {
  background: $btnborder01_colorbg_hover;
  color: $btnborder01_colortext_hover;
  outline-color: $btnborder01_colorborder_hover;
}
.btn-link,
.btn-link:focus {
  display: inline-block;
  font-size: 14px;
  color: $btnlink_colortext;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.02em;
  font-family: $default_font2;
  @include animation-value(color $value_speed);
  *[class^="icon-"] {
    margin-right: 7px;
    font-size: 20px;
    position: relative;
    top: 3px;
  }
}
.btn-link:hover {
  text-decoration: none;
  color: $btnlink_colortext_hover;
}
.btn-link-02,
.btn-link-02:focus {
  display: inline-block;
  font-size: 14px;
  color: $btnlink02_colortext;
  text-decoration: none;
  @include animation();
}
.btn-link-02:hover {
  text-decoration: none;
  color: $btnlink02_colortext_hover;
}
.btn-underline {
  display: inline-block;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  font-weight: 500;
  letter-spacing: 0.04em;
  font-family: $default_font2;
  border-bottom: 1px solid $btnunderline_colorborder;
  @include animation();
  &:not([class$="color"]) {
    color: $btnunderline_colortext;
  }
  &:hover {
    text-decoration: none;
    color: $btnunderline_colortext_hover;
    border-bottom-color: $btnunderline_colorborder_hover;
  }
}
.btn-underline.tt-white-color {
  color: $btnunderline_white_colortext;
  border-color: $btnunderline_white_colorborder;
  &:hover {
    color: $btnunderline_white_colortext_hover;
    border-color: $btnunderline_white_colorborder_hover;
  }
}

.disable {
  -moz-opacity: 0.3;
  -khtml-opacity: 0.3;
  -webkit-opacity: 0.3;
  opacity: 0.3;
  pointer-events: none;
}
.btn-top {
  @media (min-width: 790px) {
    margin-top: 40px;
  }
  @media (max-width: 789px) and (min-width: 576px) {
    margin-top: 30px;
  }
  @media (max-width: 575px) {
    margin-top: 20px;
  }
}
.tt-link {
  color: $ttlink_text;
  @include animation-value(color $value_speed);
  &:hover {
    color: $ttlink_text_hover;
  }
}
.btn.btn-small {
  height: 35px;
  padding: 6px 29px 3px;
  @media (min-width: 418px) {
    padding-bottom: 5px;
  }
  [class^="icon-"] {
    position: relative;
    top: -2px;
  }
  &:not(.tt-icon-right) {
  }
  &:not(.tt-icon-right) {
  }
}
h1,
h2,
h3,
h4,
h5,
h6 {
  display: block;
  position: relative;
  font-family: $default_font2;
  color: $default_title;
  margin-bottom: 0px;
  a {
    color: $default_title;
    @include animation-value(color $value_speed);
    &:hover {
      color: $default_title_hover;
    }
  }
}
h1:not(.tt-title-subpages):not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]) {
  font-size: 46px;
  line-height: 60px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 17px;
  @media (max-width: 1024px) {
    font-size: 36px;
    line-height: 46px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 21px;
  }
}
h2:not(.small):not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]) {
  font-size: 34px;
  line-height: 44px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 23px;
  @media (max-width: 1024px) {
    font-size: 29px;
    line-height: 39px;
    padding-bottom: 18px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    line-height: 30px;
    padding-bottom: 10px;
  }
}
h3:not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]),
h2.small:not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]) {
  font-size: 30px;
  line-height: 40px;
  font-weight: 500;
  padding-bottom: 23px;
  @media (max-width: 1024px) {
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 17px;
  }
  @media (max-width: 575px) {
    font-size: 19px;
    line-height: 29px;
    padding-bottom: 9px;
  }
}
h4:not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]) {
  font-size: 24px;
  line-height: 34px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 24px;
  @media (max-width: 1024px) {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 16px;
  }
  @media (max-width: 575px) {
    font-size: 18px;
    line-height: 28px;
    padding-bottom: 8px;
  }
}
h5:not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]) {
  font-size: 20px;
  line-height: 30px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
  @media (max-width: 1024px) {
    font-size: 22px;
    line-height: 32px;
    padding-bottom: 15px;
  }
  @media (max-width: 575px) {
    font-size: 17px;
    line-height: 27px;
    padding-bottom: 7px;
  }
}
h6:not([class^="tt-title"]):not([class^="tt-collapse"]):not([class^="tt-aside"]) {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding-bottom: 15px;
  @media (max-width: 575px) {
    font-size: 16px;
    line-height: 26px;
    padding-bottom: 6px;
  }
}
.tt-title-subpages:not(.noborder) {
  border-bottom: 1px solid $default_title_subpages_border;
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.003em;
  font-weight: 500;
  padding: 0 0 50px 0;
  margin-bottom: 46px;
  margin-top: -10px;
  @media (max-width: 1024px) {
    font-size: 33px;
    line-height: 43px;
    padding: 0 0 32px 0;
    margin-bottom: 28px;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 34px;
    padding: 0 0 26px 0;
    margin-bottom: 22px;
  }
}
.tt-title-subpages.noborder {
  font-size: 34px;
  line-height: 44px;
  letter-spacing: 0.03em;
  font-weight: 500;
  padding: 0 0 50px 0;
  margin-top: -10px;
  @media (max-width: 1024px) {
    font-size: 33px;
    line-height: 43px;
    padding: 0 0 32px 0;
  }
  @media (max-width: 575px) {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 26px;
  }
}
.tt-title-subpages:not(.text-left):not(.text-right) {
  text-align: center;
}
.tt-title-border {
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  letter-spacing: 0.03em;
  padding: 0 0 12px 0;
  margin-bottom: 30px;
  border-bottom: 1px solid $title_border;
  @media (max-width: 575px) {
    margin-bottom: 24px;
  }
}
p {
  margin: 16px 0 0 0px;
  @media (max-width: 575px) {
    margin: 10px 0 0 0px;
  }
}
mark,
.mark {
  background: $mark_bg;
  color: $mark_text;
  padding: 2px 4px;
  @include border-radius($border_radius);
}
hr {
  border: 0;
  border-top: 1px solid $hr_border;
}
hr:not([class]) {
  margin-top: 53px;
  margin-bottom: 53px;
  @media (max-width: 1024px) {
    margin-top: 35px;
    margin-bottom: 34px;
  }
  @media (max-width: 575px) {
    margin-top: 27px;
    margin-bottom: 26px;
  }
}
hr.hr-01 {
  margin-top: 53px;
  margin-bottom: 46px;
  @media (max-width: 1024px) {
    margin-top: 35px;
    margin-bottom: 33px;
  }
  @media (max-width: 575px) {
    margin-top: 27px;
    margin-bottom: 26px;
  }
}
address {
  margin: 0;
}
audio {
  width: 100%;
  display: block;
}
audio:not(:first-child) {
  margin-top: 34px;
  @media (max-width: 1229px) {
    margin-top: 28px;
  }
  @media (max-width: 575px) {
    margin-top: 22px;
  }
}
@media (max-width: 575px) {
  .col-12-575width {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
@media (max-width: 440px) {
  .col-12-440width {
    max-width: 100%;
    flex: 0 0 100%;
  }
}
input::-ms-clear {
  display: none;
}
@media (max-width: 500px) {
  .tt-table-responsive {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}
@media (max-width: 789px) {
  .tt-table-responsive-md {
    overflow-y: hidden;
    overflow-x: scroll;
  }
}
.nomargin,
#tt-pageContent .nomargin {
  margin-top: 0;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
@media (min-width: 790px) {
  .width-90 {
    width: 89%;
  }
}
.link {
  color: $ttlink_text;
  @include animation();
}
.link:hover {
  text-decoration: none;
  color: $ttlink_text_hover;
}
.divider {
  height: 46px;
  clear: both;
  overflow: hidden;
  width: 100%;
  @media (max-width: 1024px) {
    height: 40px;
  }
  @media (max-width: 575px) {
    height: 32px;
  }
}
.tt-logo {
  display: inline-block;
  img {
    height: auto;
    max-height: 20px;
  }
}

/* tt-box-info */
.tt-box-info {
  line-height: 22px;
  p {
    margin-top: 6px;
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* list */
.tt-list-dash {
  @extend ._reset-ul-style;
  > li {
    font-size: 14px;
    position: relative;
    padding: 0 0 0 19px;
    color: $list_dash_text;
  }
  > li:not(:first-child) {
    margin-top: 6px;
  }
  > li:before {
    content: "";
    width: 9px;
    height: 1px;
    background: $list_dash_bullet;
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 0;
  }
  ul {
    @extend ._reset-ul-style;
    > li {
      font-size: 14px;
      position: relative;
      padding: 6px 0 0 10px;
    }
    > li:before {
      content: "";
      width: 5px;
      height: 1px;
      background: $list_dash_bullet;
      display: inline-block;
      position: absolute;
      top: 16px;
      left: -3px;
    }
  }
}
.tt-list-dot {
  @extend ._reset-ul-style;
  > li {
    font-size: 14px;
    position: relative;
    padding: 0 0 0 11px;
    color: $list_dot_text;
    a {
      color: $list_dot_link;
      @include animation-value(color $value_speed);
    }
  }
  > li {
    a:hover {
      color: $list_dot_link_hover;
    }
  }
  > li:not(:first-child) {
    margin-top: 6px;
  }
  > li:before {
    content: "";
    width: 3px;
    height: 3px;
    background: $list_dot_bullet;
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 0;
    @include border-radius(50%);
  }
  ul {
    @extend ._reset-ul-style;
    margin: 0px 0 0 8px;
    > li {
      font-size: 14px;
      position: relative;
      padding: 6px 0 0 9px;
    }
    > li:before {
      content: "";
      width: 3px;
      height: 3px;
      background: $list_dot_bullet_sub;
      display: inline-block;
      position: absolute;
      top: 14px;
      left: -2px;
      @include border-radius(50%);
    }
  }
}
.tt-list-dot.list-dot-large {
  > li {
    padding-left: 13px;
    color: $list_dot_large_text;
  }
  > li:before {
    width: 7px;
    height: 7px;
    top: 6px;
  }
}
.tt-list-number {
  @extend ._reset-ul-style;
  counter-reset: li;
  li {
    color: $list_number_text;
  }
  li:before {
    counter-increment: li;
    content: counters(li, ".") " ";
    color: $list_content_text;
  }
  li:not(:first-child) {
    margin-top: 6px;
  }
  ol {
    padding: 6px 0 0px 24px;
    margin: 0;
    counter-reset: li;
    list-style: none;
    li:before {
      color: $list_content_text;
    }
  }
}
/* blockquote */
.tt-blockquote {
  background: $blockquote_bg;
  padding: 29px 25px 26px;
  margin: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  @include border-radius($border_radius);
  .tt-icon {
    font-size: 36px;
    line-height: 26px;
    color: $blockquote_icon;
  }
  .tt-title {
    font-size: 30px;
    line-height: 40px;
    font-family: $default_font2;
    font-weight: 500;
    font-style: italic;
    color: $blockquote_title;
    display: inline-block;
    margin-top: 15px;
    @include animation();
  }
  .tt-title-description {
    font-size: 12px;
    display: block;
    margin-top: 14px;
    color: $blockquote_title_description_text01;
    span {
      color: $blockquote_title_description_text02;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
  @media (max-width: 1024px) {
    padding-left: 15px;
    padding-right: 15px;
    .tt-icon {
      font-size: 34px;
    }
    .tt-title {
      font-size: 24px;
      line-height: 34px;
    }
  }
  @media (max-width: 575px) {
    .tt-icon {
      font-size: 33px;
    }
    .tt-title {
      font-size: 21px;
      line-height: 31px;
    }
  }
}
.tt-blockquote.add-hover:hover {
  .tt-title {
    color: $blockquote_title_hover;
  }
}
.tt-blockquote:not(:first-child) {
  margin-top: 21px;
}

.tt-blockquote:not(.text-left):not(.text-right) {
  text-align: center;
}
.tt-blockquote-02 {
  position: relative;
  padding: 0 0 0 50px;
  margin: 0;
  .tt-icon {
    position: absolute;
    top: 1px;
    left: -2px;
    font-size: 36px;
    line-height: 36px;
  }
  .tt-title {
    font-size: 30px;
    line-height: 40px;
    font-family: $default_font2;
    font-style: italic;
    font-weight: 500;
  }
  .tt-title-description {
    font-size: 14px;
    display: inline-block;
    margin-top: 9px;
    font-weight: 400;
    letter-spacing: 0.01em;
  }
  @media (max-width: 575px) {
    padding: 0;
    .tt-icon {
      position: relative;
    }
    .tt-title {
      margin-top: 3px;
    }
    .tt-title-description {
      margin-top: 6px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* table */
.tt-table-01 {
  width: 100%;
  border-top: 1px solid $table01_border;
  border-bottom: 1px solid $table01_border;
  thead {
    tr {
      th {
        font-weight: 500;
        color: $table01_thead_text;
        font-size: 14px;
        line-height: 17px;
        font-family: $default_font2;
        letter-spacing: 0.03em;
        padding: 12px 0 10px;
      }
      th:first-child {
        width: 12%;
        max-width: 68px;
        padding-right: 10px;
      }
      th:not(:first-child) {
        width: 44%;
        padding-right: 10px;
      }
    }
  }
  tbody {
    tr {
      border-top: 1px solid $table01_border;
      td {
        padding: 11px 0 11px;
        line-height: 17px;
      }
      td:first-child {
        width: 12%;
        max-width: 68px;
        padding-right: 10px;
      }
      td:not(:first-child) {
        width: 44%;
        padding-right: 10px;
      }
    }
  }
}
/* table */
.tt-table-02 {
  width: 100%;
  border-top: 1px solid $table02_border;
  border-bottom: 1px solid $table02_border;
  tbody {
    tr {
      border-top: 1px solid $table02_border;
      td:first-child {
        color: $table02_title;
        font-family: $default_font2;
        font-weight: 500;
        letter-spacing: 0.03em;
        padding-right: 10px;
      }
      td {
        line-height: 17px;
      }
      td:first-child {
        width: 31%;
        max-width: 179px;
        padding: 12px 9px 10px 0;
      }
      td:not(:first-child) {
        padding-right: 10px;
        padding: 13px 0 9px;
      }
    }
  }
}
/* tt-table-03 (product-variable.html)*/
.tt-table-03 {
  width: 100%;
  tbody {
    tr {
      td:first-child {
        color: $table03_title;
        width: 74px;
        padding: 0 10px 0 0;
      }
      td {
        padding-top: 3px;
        padding-bottom: 3px;
      }
    }
    tr:last-child {
      td {
        padding-bottom: 0;
      }
    }
  }
}

/*
Content
*/
#tt-pageContent {
  > .container-indent0:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 32px;
    @media (max-width: 1024px) {
      margin-top: 43px;
    }
    @media (max-width: 575px) {
      margin-top: 33px;
    }
  }
  > .container-indent:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 52px;
    @media (max-width: 1024px) {
      margin-top: 43px;
    }
    @media (max-width: 575px) {
      margin-top: 33px;
    }
  }
  > .container-indent1:not(.nomargin):not([class^="tt-offset"]) {
    margin-top: 63px;
    @media (max-width: 1024px) {
      margin-top: 43px;
    }
    @media (max-width: 575px) {
      margin-top: 33px;
    }
  }
  > .tt-offset-small {
    margin-top: 20px;
  }
  .del-img {
    width: 100%;
    height: auto;
  }
  .del-item-top {
    margin-top: 33px;
    @media (max-width: 1024px) {
      margin-top: 28px;
    }
    @media (max-width: 575px) {
      margin-top: 23px;
    }
  }
  .container-fluid {
    padding-right: 10px;
    padding-left: 10px;
  }
}
@media (max-width: 575px) {
  .container-fluid-custom:not(.container-fluid-custom-mobile-padding) {
    padding-right: 0;
    padding-left: 0;
  }
  #tt-pageContent .container-fluid-custom-mobile-padding {
    padding-right: 20px;
    padding-left: 20px;
  }
  .container-fluid-custom-mobile-padding-02 {
    padding-right: 10px;
    padding-left: 10px;
  }
}
.container-fluid-custom {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right: 20px;
  padding-left: 20px;
}
/* tt-block-title */
.tt-block-title {
  text-align: center;
  padding-bottom: 32px;
  .tt-title {
    font-size: 24px;
    line-height: 31px;
    font-weight: 500;
    letter-spacing: 0.04em;
    margin: 0;
    padding: 0;
    color: $block_title_text;
    a {
      color: $block_title_link;
      text-decoration: none;
      display: inline-block;
      position: relative;
      &:before {
        content: "";
        display: block;
        position: absolute;
        bottom: 2px;
        height: 2px;
        width: 100%;
        background: $block_title_link_border;
      }
      @include animation-value(border $value_speed);
    }
    a:hover {
      border-color: $block_title_link_border_hover;
      color: $block_title_link_hover;
      &:before {
        background: $block_title_link_border_hover;
      }
    }
  }
  .tt-title-small {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    margin: 0;
    padding: 0;
    color: $block_title_small_text;
    a {
      color: $block_title_small_link;
      text-decoration: none;
      display: inline-block;
      border-bottom: 2px solid $block_title_small_link_border;
      @include animation-value(border $value_speed);
    }
    a:hover {
      color: $block_title_small_link_hover;
      border-color: $block_title_small_link_border_hover;
    }
  }
  .tt-description {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    letter-spacing: 0.04em;
    font-family: $default_font2;
    color: $block_title_description_text;
    margin: 0px 0 0 0;
    padding: 0;
  }
  @media (max-width: 575px) {
    padding-bottom: 23px;
  }
}
/* tt-title-sub */
.tt-title-sub {
  font-size: 16px;
  padding-bottom: 34px;
  margin-top: 4px;
  font-weight: 500;
  letter-spacing: 0.01em;
  @media (max-width: 575px) {
    padding-bottom: 22px;
  }
}
/* box-faq */
.tt-box-faq-listing {
  margin-top: -26px;
  .tt-title {
    font-size: 16px;
    line-height: 26px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding-bottom: 15px;
  }
  .tt-box-faq {
    margin-top: 47px;
    letter-spacing: 0.0063em;
    .tt-title {
      color: $faq_title;
      a {
        color: $faq_title;
      }
      a:hover {
        color: $faq_title_hover;
      }
    }
    p {
      margin-top: 16px;
    }
    @media (min-width: 790px) {
      padding-right: 35px;
    }
  }
  .tt-box-faq:last-child {
    margin-bottom: 0;
  }
  @media (max-width: 1024px) {
    margin-top: -19px;
    .tt-box-faq {
      margin-top: 24px;
    }
  }
}
/* demo-typography */
.demo-typography {
  padding-top: 7px;
  h1 {
    margin-top: 38px;
    @media (max-width: 1024px) {
      margin-top: 23px;
    }
    @media (max-width: 575px) {
      margin-top: 19px;
    }
  }
  .tt-box-text {
    margin-top: 43px;
    @media (max-width: 1024px) {
      margin-top: 25px;
    }
    @media (max-width: 575px) {
      margin-top: 23px;
    }
  }
  .tt-blockquote {
    margin-top: 33px;
    margin-bottom: 33px;
    @media (max-width: 1024px) {
      margin-top: 28px;
      margin-bottom: 28px;
    }
    @media (max-width: 575px) {
      margin-top: 24px;
      margin-bottom: 24px;
    }
  }
}
/* .tt-about-box */
.tt-about-box {
  @media (min-width: 791px) {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: $about_box_text;
    padding: 208px 0 216px 0;
    background-position: center center;
    background-repeat: no-repeat;
    @include background-size();
    .img-mobile {
      display: none;
    }
    .tt-title {
      color: $about_box_title;
      font-size: 34px;
      line-height: 44px;
      letter-spacing: 0.02em;
      font-weight: 500;
      padding-bottom: 0;
    }
    p {
      margin-top: 23px;
      max-width: 75%;
    }
    .tt-blockquote-02 {
      margin-top: 44px;
      color: $about_box_blockquote_text;
      .tt-icon {
        color: $about_box_blockquote_icon;
      }
      .tt-title {
        font-size: 28px;
        line-height: 40px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: $about_box_blockquote_title;
      }
    }
    @media (max-width: 1229px) {
      padding: 110px 0 118px 0;
      .tt-title {
        font-size: 30px;
        line-height: 40px;
        letter-spacing: 0.02em;
      }
      p {
        max-width: 100%;
      }
      .tt-blockquote-02 {
        .tt-title {
          font-size: 22px;
          line-height: 27px;
        }
      }
    }
  }
  @media (max-width: 790px) {
    background: none !important;
    > .container {
      padding-left: 20px;
      padding-right: 20px;
    }
    .img-mobile {
      display: block;
      width: 100%;
      height: auto;
      margin-bottom: 32px;
    }
    .tt-title {
      font-size: 24px;
      line-height: 34px;
      margin-top: 32px;
      letter-spacing: 0.02em;
      padding-bottom: 4px;
    }
    .tt-blockquote-02 {
      margin-top: 25px;
      color: $about_box_blockquote_title_mobile;
      .tt-icon {
        color: $about_box_blockquote_icon_mobile;
      }
      .tt-title {
        margin-top: 0px;
        padding-bottom: 0;
        font-size: 23px;
      }
    }
  }
  div[class^="col-"] {
    > *:nth-child(1) {
      margin-top: 0;
    }
  }
}
@media (min-width: 576px) {
  html.tt-boxed .tt-about-box .container {
    padding-right: 40px;
    padding-left: 40px;
  }
}
.tt-about-col-list {
  .tt-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding-bottom: 15px;
  }
  div[class^="col"] {
    margin-top: 52px;
    @media (max-width: 1024px) {
      margin-top: 42px;
    }
    @media (max-width: 575px) {
      margin-top: 25px;
    }
  }
  margin-top: -52px;
  @media (max-width: 1024px) {
    margin-top: -42px;
  }
  @media (max-width: 790px) {
    padding-left: 10px;
    padding-right: 10px;
  }
  @media (max-width: 575px) {
    margin-top: -25px;
  }
}
.tt-about02-col-list {
  .tt-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding-bottom: 15px;
  }
  div[class^="col"] {
    margin-top: 52px;
    @media (max-width: 1024px) {
      margin-top: 42px;
    }
    @media (max-width: 575px) {
      margin-top: 25px;
    }
  }
  margin-top: -52px;
  @media (max-width: 1024px) {
    margin-top: -42px;
  }
  @media (max-width: 575px) {
    margin-top: -25px;
  }
}
/* tt-about-box-02 */
.tt-about-box-02 {
  .tt-title {
    font-size: 30px;
    line-height: 40px;
    font-weight: 500;
    letter-spacing: 0em;
    margin-top: -4px;
    @media (max-width: 1229px) {
      font-size: 25px;
      line-height: 35px;
    }
    @media (max-width: 789px) {
      font-size: 22px;
      line-height: 32px;
    }
  }
}
/* tt-col-img */
.tt-col-img {
  margin-top: -20px;
  img {
    width: 100%;
    height: auto;
    margin-top: 20px;
  }
}
/* tt-contact-box */
.tt-contact-box {
  @media (min-width: 791px) {
    background-image: url("../images/custom/contact-img-01.jpg");
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: red;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 302px 15px 320px;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    a {
      color: $contact_box_link;
      &:hover {
        color: $contact_box_link_hover;
      }
    }
    .tt-title {
      color: $contact_box_title;
      font-size: 34px;
      line-height: 44px;
      margin: 0;
      padding: 0;
      font-weight: 500;
      letter-spacing: 0.03em;
    }
    address {
      color: $contact_box_text;
      margin: 24px 0 0 0;
    }
    @media (max-width: 1229px) {
      padding-top: 202px;
      padding-bottom: 220px;
      .tt-title {
        font-size: 30px;
        line-height: 40px;
      }
    }
    .img-mobile {
      display: none;
    }
  }
  @media (max-width: 790px) {
    background: none !important;
    .img-mobile {
      width: 100%;
      height: auto;
      margin-bottom: 32px;
    }
    .tt-title {
      font-size: 24px;
      line-height: 34px;
    }
    address {
      margin-top: 14px;
    }
    .container {
      > *:nth-child(1) {
        margin-top: 0;
      }
    }
  }
  div[class^="col-"] {
    > *:nth-child(1) {
      margin-top: 0;
    }
  }
}
.tt-contact-col-list {
  .tt-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.03em;
    padding-bottom: 15px;
  }
  div[class^="col"] {
    margin-top: 51px;
    @media (max-width: 1024px) {
      margin-top: 42px;
    }
    @media (max-width: 790px) {
      margin-top: 24px;
    }
  }
  margin-top: -51px;
  @media (max-width: 1024px) {
    margin-top: -42px;
  }
  @media (max-width: 575px) {
    margin-top: -25px;
  }
}
.tt-contact02-col-list {
  div[class^="col"] {
    margin-top: 51px;
    @media (max-width: 1024px) {
      margin-top: 42px;
    }
    @media (max-width: 575px) {
      margin-top: 25px;
    }
  }
  margin-top: -51px;
  @media (max-width: 1024px) {
    margin-top: -42px;
  }
  @media (max-width: 575px) {
    margin-top: -25px;
  }
}
/* tt-contact-info */
.tt-contact-info:not(.text-left):not(.text-right) {
  text-align: center;
}
.tt-contact-info {
  .tt-icon {
    font-size: 55px;
    line-height: 1.1;
    color: $contact_info_icon;
  }
  .tt-title {
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: $contact_info_title;
    margin: 15px 0 0 0;
    padding-bottom: 0;
  }
  address {
    margin: 15px 0 0 0;
    letter-spacing: 0.01em;
  }
  @media (max-width: 1229px) {
    .tt-icon {
      font-size: 55px;
    }
    .tt-title {
      font-size: 20px;
      line-height: 30px;
      margin: 15px 0 0 0;
      padding-bottom: 0;
    }
  }
  @media (max-width: 575px) {
    padding-bottom: 0px;
    .tt-icon {
      font-size: 48px;
    }
    .tt-title {
      font-size: 18px;
      line-height: 28px;
      margin: 5px 0 0 0;
    }
    address {
      margin-top: 8px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* map */
.contact-map {
  width: 100%;
  #map {
    height: 450px;
    width: 100%;
  }
}
/* form */
::-webkit-input-placeholder {
  color: $form_placeholder;
}
::-moz-placeholder {
  color: $form_placeholder;
}
:-moz-placeholder {
  color: $form_placeholder;
}
:-ms-input-placeholder {
  color: $form_placeholder;
}

input:not(.tt-search-input):focus::-webkit-input-placeholder {
  color: transparent;
}
input:not(.tt-search-input):focus:-moz-placeholder {
  color: transparent;
}
input:not(.tt-search-input):focus::-moz-placeholder {
  color: transparent;
}
input:not(.tt-search-input):focus:-ms-input-placeholder {
  color: transparent;
}

textarea:focus::-webkit-input-placeholder {
  color: transparent;
}
textarea:focus:-moz-placeholder {
  color: transparent;
}
textarea:focus::-moz-placeholder {
  color: transparent;
}
textarea:focus:-ms-input-placeholder {
  color: transparent;
}

/* form-default */
.form-default {
  .form-group {
    margin-bottom: 20px;
    label:not(.error) {
      font-size: 14px;
      color: $form_control_label;
      font-family: $default_font2;
      font-weight: 500;
      padding-bottom: 4px;
      letter-spacing: 0.02em;
    }
  }
  .form-control {
    background: $form_control_bg;
    font-size: 14px;
    border: 1px solid $form_control_border;
    padding: 11px 12px 10px 15px;
    font-family: $default_font;
    box-shadow: none;
    outline: none;
    color: $form_control_text;
    font-weight: 300;
    @include border-radius($border_radius);
    @include animation();
  }
  .form-control:not(textarea) {
    height: 40px;
    @media (max-width: 575px) {
      height: 39px;
    }
  }
  input.form-control,
  textarea.form-control {
    -webkit-appearance: none;
  }
  select.form-control {
    padding: 10px 12px 11px 15px;
    cursor: pointer;
  }
  select.form-control:not([size]):not([multiple]) {
    height: auto;
  }
  .form-control.error {
    border-color: red;
  }
  .form-control:focus {
    border-color: $form_control_border_focus;
    background: $form_control_bg_focus;
  }
  .tt-required {
    display: inline-block;
    float: right;
    color: $form_required_text;
    padding-left: 5px;
  }
}

.list-form-inline {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -15px;
  li {
    margin-left: 15px;
  }
}
.list-form-column {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  list-style: none;
  padding: 0;
  margin: 0 0 0 -15px;
  li {
    margin-left: 15px;
  }
}
/* checkbox-group */
.checkbox-group {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  input[type="checkbox"] {
    display: none;
  }
  label {
    padding-left: 25px;
    cursor: pointer;
    font-size: 14px;
    color: $checkbox_label;
    @include animation();
    span {
      display: block;
      position: absolute;
      left: 0;
      -webkit-transition-duration: 0.12s;
      -moz-transition-duration: 0.12s;
      transition-duration: 0.12s;
    }
    .box {
      background: $checkbox_bg;
      @include border-radius($border_radius_small);
      height: 17px;
      width: 17px;
      z-index: 9;
      margin-top: 2px;
      border: 1px solid $checkbox_border;
    }
    .check {
      top: 2px;
      left: 0px;
      opacity: 0;
      z-index: 10;
      @include scale(0);
      background: $checkbox_bg_active;
      @include border-radius($border_radius_small);
      width: 17px;
      height: 17px;
      line-height: 1;
    }
    .check:before {
      content: "\ea11";
      color: $checkbox_icon_active;
      font-size: 15px;
      line-height: 1;
      position: relative;
      left: 1px;
      top: 1px;
      font-family: $default_font_icon;
      font-style: normal;
      font-weight: 400;
    }
  }
}
.checkbox-group:hover {
  label {
    color: $checkbox_label_hover;
  }
}
input[type="checkbox"]:checked ~ label .check {
  opacity: 1;
  @include scale(1);
}
/* radio-group */
.radio {
  display: block;
  cursor: pointer;
  margin: 0 0 8px;
  transition: all 0.15s linear;
  -ms-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  -o-transition: all 0.15s linear;
  color: $radio_text;
  input {
    width: 1px;
    height: 1px;
    opacity: 0;
  }
  input:focus + .outer .inner {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
    background-color: $radio_bg_active;
  }
  .outer {
    width: 16px;
    height: 16px;
    display: block;
    float: left;
    margin: 3px 9px 0 0;
    background-color: $radio_bg;
    border: 1px solid $radio_bg_border;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
  }
  .inner {
    width: 10px;
    height: 10px;
    top: 2px;
    left: 2px;
    display: block;
    background-color: $radio_bg_active;
    opacity: 0;
    position: relative;
    -webkit-transform: scale(0);
    -moz-transform: scale(0);
    -ms-transform: scale(0);
    -o-transform: scale(0);
    transform: scale(0);
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    transition: all 0.2s linear;
    -ms-transition: all 0.2s linear;
    -webkit-transition: all 0.2s linear;
    -o-transition: all 0.2s linear;
  }
  input:checked + .outer .inner {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.radio:hover {
  color: $radio_text_hover;
  .inner {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
  }
}
/* contact-form */
.contact-form {
  margin-top: 39px;
  textarea {
    height: 160px;
  }
  .btn {
    margin-top: 20px;
  }
  @media (max-width: 575px) {
    margin-top: 33px;
  }
  p {
    padding-bottom: 20px;
  }
  .form-group {
    position: relative;
  }
  label.error {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 56px;
    left: 0;
    padding: 8px 19px 6px 43px;
    background: $errors_bg;
    color: $errors_text;
    font-size: 14px;
    line-height: 22px;
    z-index: 1;
    &:before {
      content: "\ea27";
      font-family: $default_font_icon;
      display: inline-block;
      font-size: 20px;
      line-height: 1;
      position: absolute;
      top: 7px;
      left: 19px;
      color: $errors_icon;
    }
    &:after {
      content: "";
      position: absolute;
      top: -12px;
      left: 50%;
      border: 6px solid transparent;
      border-bottom: 6px solid $errors_bg;
    }
  }
}

/* tt-box-thumb (services.html)*/
.tt-box-thumb-listing {
  margin-top: -53px;
  @media (max-width: 1024px) {
    margin-top: -35px;
  }
  @media (max-width: 1024px) {
    margin-top: -28px;
  }
}
.tt-box-thumb {
  margin-top: 53px;
  text-align: center;
  .tt-img {
    overflow: hidden;
    display: block;
    backface-visibility: hidden;
    img {
      width: 100%;
      height: auto;
    }
  }
  .tt-title {
    margin-top: 22px;
    font-weight: 500;
    font-size: 20px;
    letter-spacing: 0.03em;
    line-height: 30px;
    padding: 0;
    color: $box_thumb_title;
    a {
      color: $box_thumb_title;
    }
    a:hover {
      color: $box_thumb_title_hover;
    }
  }
  p {
    margin-top: 15px;
    padding-left: 5px;
    padding-right: 5px;
  }
  @media (max-width: 1024px) {
    margin-top: 35px;
    .tt-title {
      font-size: 18px;
      line-height: 28px;
      margin-top: 19px;
    }
    p {
      margin-top: 11px;
    }
  }
  @media (max-width: 575px) {
    margin-top: 23px;
    .tt-title {
      font-size: 16px;
      line-height: 26px;
      margin-top: 16px;
    }
    p {
      margin-top: 7px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
.tt-page404 {
  background: $page404_bg;
  color: $page404_text;
  text-align: center;
  padding: 79px 20px 103px;
  img {
    max-width: 100%;
    height: auto;
  }
  .tt-title {
    color: $page404_title;
    padding: 0;
    margin: 42px 0 0 0;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 44px;
  }
  p {
    margin-top: 2px;
    font-size: 15px;
  }
  .btn {
    margin-top: 34px;
  }
  @media (max-width: 1024px) {
    img {
      max-width: 400px;
    }
    .tt-title {
      font-size: 28px;
      line-height: 40px;
    }
  }
  @media (max-width: 789px) {
    padding: 35px 20px 59px;
    img {
      width: 350px;
      max-width: 70%;
    }
    .tt-title {
      font-size: 25px;
      line-height: 34px;
    }
  }
  @media (max-width: 575px) {
    .tt-title {
      font-size: 23px;
      line-height: 32px;
      margin: 28px 0 0 0;
    }
    .btn {
      margin-top: 25px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* tt-empty-wishlist*/
.tt-empty-wishlist {
  text-align: center;
  padding: 102px 20px 102px;
  color: $empty_cart_text;
  img {
    max-width: 100%;
    height: auto;
  }
  .icon-svg {
    margin: 17px 0 0 0;
    svg {
      width: 58px;
      height: 58px;
    }
  }
  .tt-title {
    color: $empty_cart_title;
    padding: 0;
    margin: 13px 0 0 0;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 44px;
  }
  p {
    color: #777777;
    font-size: 26px;
    line-height: 1.3;
    font-weight: 400;
  }
  .btn {
    margin-top: 34px;
  }
  @media (max-width: 1024px) {
    padding: 71px 20px 82px;
    .tt-title {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media (max-width: 575px) {
    padding: 51px 15px 62px;
    .tt-title {
      font-size: 24px;
      line-height: 34px;
    }
    p {
      font-size: 22px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* tt-empty-cart*/
.tt-empty-cart {
  text-align: center;
  padding: 102px 20px 102px;
  color: $empty_cart_text;
  img {
    max-width: 100%;
    height: auto;
  }
  .tt-icon {
    font-size: 150px;
    line-height: 1.1;
    color: $empty_cart_icon;
  }
  .tt-title {
    color: $empty_cart_title;
    padding: 0;
    margin: 13px 0 0 0;
    font-size: 34px;
    font-weight: 500;
    letter-spacing: 0.03em;
    line-height: 44px;
  }
  p {
    margin-top: 2px;
    font-size: 15px;
  }
  .btn {
    margin-top: 34px;
  }
  @media (max-width: 1024px) {
    padding: 71px 20px 82px;
    .tt-icon {
      font-size: 130px;
    }
    .tt-title {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media (max-width: 575px) {
    padding: 51px 15px 62px;
    .tt-icon {
      font-size: 120px;
    }
    .tt-title {
      font-size: 24px;
      line-height: 34px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* tt-empty-search*/
.tt-empty-search {
  text-align: center;
  padding: 106px 20px 102px;
  color: $empty_search_text;
  img {
    max-width: 100%;
    height: auto;
  }
  .tt-icon {
    font-size: 150px;
    line-height: 1.1;
    color: $empty_search_icon;
  }
  .tt-title {
    color: $empty_search_title;
    padding: 0;
    margin: 9px 0 0 0;
    font-size: 34px;
    font-weight: 500;
    line-height: 44px;
  }
  p {
    margin-top: 2px;
    font-size: 15px;
  }
  .btn {
    margin-top: 34px;
  }
  @media (max-width: 1024px) {
    padding: 71px 20px 82px;
    .tt-icon {
      font-size: 130px;
    }
    .tt-title {
      font-size: 28px;
      line-height: 38px;
    }
  }
  @media (max-width: 575px) {
    padding: 51px 15px 62px;
    .tt-icon {
      font-size: 120px;
    }
    .tt-title {
      font-size: 24px;
      line-height: 34px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* tt-coming-soon */
.tt-coming-soon {
  padding: 27px 0 90px 0;
  overflow: auto;
  @include background-size();
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  @media (min-width: 734px) {
    height: 100vh;
  }
  form {
    .form-group {
      display: -ms-inline-flexbox;
      display: -webkit-inline-flex;
      display: inline-flex;
    }
    .form-control {
      min-width: 254px;
      margin-top: 20px;
      -webkit-border-top-right-radius: 0px;
      -webkit-border-bottom-right-radius: 0px;
      -moz-border-radius-topright: 0px;
      -moz-border-radius-bottomright: 0px;
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .btn {
      margin-top: 20px;
      -webkit-border-top-left-radius: 0px;
      -webkit-border-bottom-left-radius: 0px;
      -moz-border-radius-topleft: 0px;
      -moz-border-radius-bottomleft: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }
  }
  h1.tt-title {
    font-size: 34px;
    line-height: 47px;
    margin: 54px 0 0 0;
    padding: 0;
    font-weight: 500;
    letter-spacing: 0.03em;
    color: $coming_soon_title01;
  }
  h2.tt-title {
    font-size: 16px;
    line-height: 26px;
    margin: 31px 0 0 0;
    padding: 0 0 2px 0;
    font-weight: 500;
    color: $coming_soon_title02;
    width: 100%;
  }
  .description {
    max-width: 584px;
    margin: 21px auto 47px;
  }
  .tt-coming-soon-content {
    > *:nth-child(1) {
      margin-top: 0;
    }
  }
  @media (max-width: 1229px) {
    padding-top: 17px;
    h1.tt-title {
      font-size: 30px;
      line-height: 40px;
    }
    .description {
      margin-bottom: 37px;
    }
    form {
      margin-top: 0px;
    }
    h2.tt-title {
      margin-top: 21px;
    }
  }
  @media (min-width: 1025px) {
    background: url("../images/custom/comingsoon-img-01.jpg") center top
      no-repeat;
    @include background-size();
  }
  @media (max-width: 1024px) {
    padding-top: 15px;
    padding-bottom: 27px;
    h1.tt-title {
      font-size: 29px;
      line-height: 39px;
      margin-top: 35px;
    }
    .description {
      margin-bottom: 27px;
    }
    form {
      .form-control,
      .btn {
        margin-top: 13px;
      }
      .form-group {
        margin-bottom: 13px;
      }
    }
  }
  @media (max-width: 575px) {
    padding-bottom: 10px;
    h1.tt-title {
      font-size: 23px;
      line-height: 33px;
      margin-top: 15px;
    }
    .description {
      margin-top: 10px;
      margin-bottom: 0px;
    }
    form {
      .form-group {
        display: block;
      }
      .form-control {
        margin-top: 13px;
      }
      .btn {
        margin-top: 13px;
      }
      .form-group {
        margin-bottom: 7px;
      }
      .form-control {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
      }
      .btn {
        -webkit-border-radius: 6px;
        -moz-border-radius: 6px;
        border-radius: 6px;
      }
    }
  }
}

.coming-soon-top {
  position: fixed;
  top: 100px;
  width: 100%;
  left: 0;
  z-index: 10;
  p {
    font-size: 12px;
  }
  .tt-social-icon {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 11px;
  }
  @media (max-width: 1229px) {
    top: 52px;
  }
  @media (max-width: 1024px) {
    top: 22px;
    .tt-social-icon {
      margin-top: 5px;
    }
  }
  @media (max-width: 789px) {
    position: inherit;
    top: inherit;
    margin-top: 25px;
  }
}

.coming-soon-bottom {
  position: fixed;
  bottom: 72px;
  width: 100%;
  left: 0;
  p {
    font-size: 12px;
  }
  .tt-social-icon {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-top: 11px;
  }
  @media (max-width: 1229px) {
    bottom: 52px;
  }
  @media (max-width: 1024px) {
    bottom: 22px;
    .tt-social-icon {
      margin-top: 5px;
    }
  }
  @media (max-width: 789px) {
    position: inherit;
    bottom: inherit;
    margin-top: 25px;
  }
}
/* social-icon */
.tt-social-icon {
  @extend ._reset-ul-style;
  @extend ._list-inline-item;
  li {
    a {
      font-size: 18px;
      line-height: 26px;
      color: $socialIcon_pageContent_icon;
      padding: 4px;
      @include animation();
    }
    a:hover {
      color: $socialIcon_pageContent_icon_hover;
    }
  }
  li:first-child {
    margin-right: 5px;
  }
  li:last-child {
    margin-left: 5px;
  }
  li:not(:last-child):not(:first-child) {
    margin: 0 5px;
  }
}
/* login-form-box */
.tt-login-form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  .tt-item {
    border: 1px solid $loginForm_border;
    padding: 32px 39px 20px;
    height: 100%;
    .tt-title {
      font-size: 20px;
      line-height: 30px;
      padding: 0 0 14px 0;
      font-weight: 500;
    }
    p {
      margin-top: 0;
    }
    .form-top {
      margin-top: 17px;
    }
    .btn:not(.btn-top) {
      margin-top: 4px;
      margin-bottom: 5px;
    }
    .btn-top {
      margin-top: 25px;
    }
    .btn {
      margin-right: 12px;
    }
    .additional-links {
      list-style: none;
      margin: 0;
      padding: 0;
      display: inline-block;
      position: relative;
      a {
        color: $loginForm_links;
        @include animation();
      }
      a:hover {
        color: $loginForm_links_hover;
      }
    }
  }
  @media (max-width: 789px) {
    display: block;
    align-items: flex-start;
    margin-top: -20px;
    .tt-item:first-child {
      margin-top: 20px;
      height: auto;
    }
  }
  @media (max-width: 440px) {
    .tt-item {
      padding: 20px 20px 10px;
    }
  }
}
/*
	slick
*/
/*tt-carousel-products*/
.tt-carousel-products.slick-slider {
  .slick-slide[class^="col-"] {
    max-width: inherit;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-animated-show-js {
  opacity: 0;
  @include animation-value(opacity $value_speed);
}
.slick-animated-show-js.slick-initialized {
  opacity: 1;
}
.slick-track {
  margin: auto;
}
.tt-slick-row {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
/* (blog-single-post-gallery.html) */
.tt-slick-button {
  font-size: 0;
  line-height: 0;
  .slick-arrow {
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    background: $slick_button_bg;
    color: $slick_button_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    background: $slick_button_bg_hover;
    color: $slick_button_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev:before {
    content: "\e90d";
  }
  .slick-next {
    margin-left: 4px;
  }
  .slick-next:before {
    content: "\e90e";
  }
}
/* slick (product.html) */
.tt-slick-button-vertical {
  font-size: 0;
  line-height: 0;
  .slick-arrow {
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    background: $slick_button_vertical_bg;
    color: $slick_button_vertical_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    background: $slick_button_vertical_bg_hover;
    color: $slick_button_vertical_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev:before {
    content: "\e90f";
  }
  .slick-next {
    left: 42px;
  }
  .slick-next:before {
    content: "\e90c";
  }
}
.tt-slick-quantity {
  font-size: 14px;
  line-height: 1;
  color: $slick_quantity_text;
  font-family: $default_font2;
  .account-number {
    font-size: 20px;
    color: $slick_quantity_number;
  }
}
/* arrow-location-01 (blog listing pages)*/
.arrow-location-01 {
  .slick-arrow {
    position: absolute;
    bottom: 10px;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    background: $slick_location01_arrow_bg;
    color: $slick_location01_arrow_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    background: $slick_location01_arrow_bg_hover;
    color: $slick_location01_arrow_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev {
    right: 57px;
  }
  .slick-prev:before {
    content: "\e90d";
  }
  .slick-next {
    right: 10px;
  }
  .slick-next:before {
    content: "\e90e";
  }
}
/* arrow-location-02 (product-02.html)*/
.arrow-location-02 {
  .slick-arrow {
    position: absolute;
    bottom: -58px;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    background: $slick_location02_arrow_bg;
    color: $slick_location02_arrow_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    background: $slick_location02_arrow_bg_hover;
    color: $slick_location02_arrow_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-prev:before {
    content: "\e90d";
  }
  .slick-next {
    left: 47px;
  }
  .slick-next:before {
    content: "\e90e";
  }
}
/* arrow-location-03 (desktop menu)*/
.arrow-location-03 {
  .slick-arrow {
    position: absolute;
    top: -46px;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    font-weight: 500;
    color: $slick_location03_arrow_text;
    background: $slick_location03_arrow_bg;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    color: $slick_location03_arrow_text_hover;
    background: $slick_location03_arrow_bg_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev {
    right: 20px;
  }
  .slick-prev:before {
    content: "\e90d";
  }
  .slick-next {
    right: -4px;
  }
  .slick-next:before {
    content: "\e90e";
  }
}
/* arrow-location-tab (index pages) */
.arrow-location-tab {
  .slick-arrow {
    position: absolute;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    color: $slick_locationTab_arrow_text;
    background: $slick_locationTab_arrow_bg;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    background: $slick_locationTab_arrow_bg_hover;
    color: $slick_locationTab_arrow_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev:before {
    content: "\e90d";
    margin-right: 2px;
  }
  .slick-next:before {
    content: "\e90e";
    margin-left: 2px;
  }
  @media (min-width: 1025px) {
    &.arrow-location-tab01 {
      .slick-arrow {
        top: -43px;
      }
    }
    .slick-arrow {
      top: -33px;
    }
    .slick-prev {
      right: 57px;
    }
    .slick-next {
      right: 10px;
    }
  }
  @media (max-width: 1024px) {
    .slick-arrow {
      top: 50%;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
  }
}
/* arrow-location-right-top (product single pages)*/
.arrow-location-right-top {
  .slick-arrow {
    position: absolute;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    background: $slick_locationRightTop_arrow_bg;
    color: $slick_locationRightTop_arrow_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    background: $slick_locationRightTop_arrow_bg_hover;
    color: $slick_locationRightTop_arrow_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev:before {
    content: "\e90d";
  }
  .slick-next:before {
    content: "\e90e";
  }
  @media (min-width: 1025px) {
    .slick-arrow {
      top: -28px;
    }
    .slick-prev {
      right: 57px;
    }
    .slick-next {
      right: 10px;
    }
  }
  @media (max-width: 1024px) {
    .slick-arrow {
      top: 50%;
    }
    .slick-prev {
      left: 0px;
    }
    .slick-next {
      right: 0px;
    }
  }
}
/* arrow-location-center (quickViewModal)*/
.arrow-location-center {
  .slick-arrow,
  .slick-arrow:active {
    position: absolute;
    top: 50%;
    margin-top: -19px;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    color: $slick_locationCenter_arrow_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    color: $slick_locationCenter_arrow_text_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-prev:before {
    content: "\e90d";
  }
  .slick-next {
    right: 0px;
  }
  .slick-next:before {
    content: "\e90e";
  }
}
/* arrow-location-center-02 (index-07.html)*/
.arrow-location-center-02 {
  .slick-arrow,
  .slick-arrow:active {
    position: absolute;
    top: 50%;
    margin-top: -19px;
    z-index: 7;
    cursor: pointer;
    font-size: 0;
    line-height: 0;
    background: none;
    border: none;
    width: 38px;
    height: 38px;
    background: $slick_locationCenter02_arrow_bg;
    color: $slick_locationCenter02_arrow_text;
    font-weight: 500;
    @include border-radius(50%);
    @include animation();
  }
  .slick-arrow:hover {
    color: $slick_locationCenter02_arrow_text_hover;
    background: $slick_locationCenter02_arrow_bg_hover;
  }
  .slick-arrow:before {
    font-family: $default_font_icon;
    font-size: 20px;
    line-height: 1;
  }
  .slick-prev {
    left: 0px;
  }
  .slick-prev:before {
    content: "\e90d";
    margin-right: 2px;
  }
  .slick-next {
    right: 0px;
  }
  .slick-next:before {
    content: "\e90e";
    margin-left: 2px;
  }
}
body:not(.touch-device) .arrow-location-center-02.tt-arrow-hover {
  .slick-arrow {
    @include opacity(0);
  }
}
body:not(.touch-device) .arrow-location-center-02.tt-arrow-hover:hover {
  .slick-arrow {
    @include opacity(1);
  }
}
/* slick - style */
.slick-slider {
  .slick-dots {
    @extend ._reset-ul-style;
    margin-top: 22px;
    position: absolute;
    width: 100%;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: stretch;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start;
    li {
      margin: 0 3px;
      padding: 0 2px;
      cursor: pointer;
      button {
        width: 14px;
        height: 14px;
        background: $slick_slider_dots_bg;
        border: none;
        @include border-radius(50%);
        font-size: 0;
        line-height: 0;
        cursor: pointer;
      }
    }
    li:hover {
      button {
        background: $slick_slider_dots_bg_hover;
      }
    }
    .slick-active {
      button {
        background: $slick_slider_dots_bg_active;
      }
    }
  }
}
/* (blog masonry col) */
.tt-arrow-type-02.slick-slider {
  .slick-arrow {
    background: $slick_type02_arrow_bg;
    color: $slick_type02_arrow_text;
  }
  .slick-arrow:hover {
    background: $slick_type02_arrow_bg_hover;
    color: $slick_type02_arrow_text_hover;
  }
  .slick-next {
    right: 20px;
  }
  .slick-prev {
    left: 20px;
  }
}
.wrapper-social-icon {
  background: $wrapper_socialIcon_bg;
  padding: 12px 5px 10px;
}
/* tt-gift-layout (gift-cart.html)*/
.tt-gift-layout {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 82px 0;
  .tt-title {
    margin-top: 81px;
    font-size: 34px;
    line-height: 1.1;
    color: $gift_title;
  }
  .tt-gift-box {
    margin-top: 53px;
    max-width: 434px;
    overflow: hidden;
    position: relative;
    @include border-radius(9px);
    img {
      max-width: 100%;
      height: auto;
    }
    .tt-description {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-flex-wrap: nowrap;
      -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
      -webkit-justify-content: flex-end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      .tt-sum {
        color: $gift_price;
        font-size: 32px;
        font-family: $default_font2;
        position: absolute;
        top: 17px;
        right: 15px;
        font-weight: 500;
        letter-spacing: 0.02em;
        @media (max-width: 575px) {
          font-size: 27px;
        }
      }
      .tt-code {
        background-color: $gift_code;
        border: 7px solid $gift_code_border;
        margin: 20px;
        position: relative;
        @include border-radius(3px);
        span {
          display: block;
          position: relative;
          padding: 13px 14px 11px;
          font-size: 25px;
          line-height: 1;
          letter-spacing: 0.06em;
          color: $gift_code_text;
        }
        span:before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 1px;
          height: 100%;
          background: url("../images/custom/tt-gift-box-border2.png") left top
            repeat-y;
        }
        span:after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 1px;
          height: 100%;
          background: url("../images/custom/tt-gift-box-border2.png") right top
            repeat-y;
        }
      }
      .tt-code:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: url("../images/custom/tt-gift-box-border1.png") left top
          repeat-x;
      }
      .tt-code:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: url("../images/custom/tt-gift-box-border1.png") left top
          repeat-x;
      }
    }
  }
  p {
    margin-top: 33px;
    padding-left: 10px;
    padding-right: 10px;
    text-align: center;
  }
  .tt-gift-dissembled {
    margin-top: 35px;
    img {
      max-width: 100%;
      height: auto;
    }
  }
  .btn-border {
    margin-top: 62px;
  }
  .btn-link {
    margin-top: 32px;
  }
  @media (max-width: 1024px) {
    padding: 42px 0;
    .tt-title {
      margin-top: 41px;
      font-size: 31px;
    }
    .tt-gift-box {
      margin-top: 44px;
      max-width: 380px;
      .tt-description {
        .tt-code {
          span {
            font-size: 22px;
            letter-spacing: 0.04em;
          }
        }
      }
    }
    .tt-gift-dissembled {
      margin-top: 31px;
    }
    .btn-border {
      margin-top: 40px;
    }
    .btn-link {
      margin-top: 23px;
    }
  }
  @media (max-width: 575px) {
    padding: 22px 0;
    .tt-title {
      margin-top: 35px;
      font-size: 26px;
    }
    .tt-gift-box {
      margin-top: 36px;
      max-width: 290px;
      .tt-description {
        .tt-code {
          margin: 15px;
          span {
            font-size: 18px;
            letter-spacing: 0.02em;
          }
        }
      }
    }
    p {
      margin-top: 25px;
      line-height: 19px;
    }
    .tt-gift-dissembled {
      margin-top: 24px;
    }
    .btn-border {
      margin-top: 31px;
    }
    .btn-link {
      margin-top: 18px;
    }
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* tt-back-to-top */
@media (min-width: 790px) {
  .tt-back-to-top {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;

    -webkit-transition: 0.4s;
    -moz-transition: 0.4s;
    -ms-transition: 0.4s;
    transition: 0.4s;

    width: 62px;
    height: 62px;
    background-color: $backToTop_desctope_bg;
    color: $backToTop_desctope_text;
    font-size: 0;
    line-height: 0;
    position: fixed;
    bottom: -64px;
    right: 5px;
    z-index: 20;
    @include border-radius($border_radius);
  }
  .tt-back-to-top:before {
    font-family: $default_font_icon !important;
    content: "\e903";
    display: block;
    font-size: 20px;
    line-height: 1;
    position: relative;
    top: -1px;
    @include animation-value(top 0.2s);
  }
  .tt-back-to-top:hover {
    background-color: $backToTop_desctope_bg_hover;
    color: $backToTop_desctope_text_hover;
  }
  .tt-back-to-top:hover:before {
    top: -4px;
  }
  .tt-back-to-top.tt-show {
    bottom: 50px;
  }
}

@media (max-width: 789px) {
  .tt-back-to-top {
    background: $backToTop_mobile_bg;
    color: $backToTop_mobile_text;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 12px;
    font-family: $default_font2;
    font-weight: 500;
    padding: 5px 0 33px 0;
    @include animation-value(color $value_speed);
    &.tt-align-center {
      padding: 20px 0 18px 0;
    }
  }
  .tt-back-to-top:before {
    font-family: $default_font_icon !important;
    content: "\e9c9";
    display: block;
    font-size: 20px;
    line-height: 1;
  }
  .tt-back-to-top:hover {
    background: $backToTop_mobile_bg_hover;
    color: $backToTop_mobile_text_hover;
  }
}
/* tt-breadcrumb */
.tt-breadcrumb {
  background: $breadcrumb_bg;
  ul {
    @extend ._reset-ul-style;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-align-content: center;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    li {
      font-size: 12px;
      padding: 4px 6px 4px 11px;
      position: relative;
      color: $breadcrumb_text;
      a {
        color: $breadcrumb_link;
        @include animation-value(color $value_speed);
      }
      a:hover {
        color: $breadcrumb_link_hover;
      }
    }
    li:not(:first-child):before {
      content: "/";
      position: absolute;
      display: inline-block;
      left: 0;
      top: 4px;
    }
    li:first-child {
      padding-left: 0;
    }
  }
  @media (max-width: 575px) {
    & {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
/* tt-services-block */
.tt-layout-02 {
  background: #f7f8fa;
}
.tt-services-listing.tt-layout-02 {
  .tt-services-block {
    margin-top: 16px;
    padding-bottom: 14px;
  }
}
.tt-services-listing:not(.tt-layout-02) {
  margin-top: -30px;
  .tt-services-block {
    margin-top: 30px;
  }
  @media (max-width: 575px) {
    margin-top: -35px;
    .tt-services-block {
      margin-top: 37px;
    }
  }
}
.tt-services-listing-aligment {
  @media (min-width: 791px) {
    margin-bottom: -5px;
  }
  @media (max-width: 790px) and (min-width: 576px) {
    margin-bottom: -12px;
  }
  @media (max-width: 575px) {
    margin-bottom: -10px;
  }
}
.tt-services-block {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  .tt-col-icon {
    -webkit-align-self: flex-start;
    -ms-flex-item-align: start;
    align-self: flex-start;
    font-size: 50px;
    color: $servicesBlock_icon;
    [class^="icon-"] {
      position: relative;
      top: -5px;
    }
  }
  .tt-col-icon + .tt-col-description {
    @media (min-width: 576px) {
      padding-left: 21px;
    }
    @media (max-width: 575px) {
      padding-left: 11px;
    }
  }
  .tt-col-description {
    -webkit-flex: 2 1 auto;
    -ms-flex: 2 1 auto;
    flex: 2 1 auto;
    line-height: 1.3;
    color: $servicesBlock_description_text;
    .tt-title {
      padding: 0;
      margin: 0;
      font-size: 16px;
      letter-spacing: 0.03em;
      font-weight: 500;
      color: $servicesBlock_title_text;
      @include animation();
    }
    p {
      margin-top: 7px;
      font-size: 14px;
      line-height: 22px;
    }
    > *:nth-child(1) {
      margin-top: 0;
    }
  }
}
.tt-services-block:hover {
  text-decoration: none;
  .tt-title {
    color: $servicesBlock_title_text_hover;
  }
}
.tt-product-single-aside {
  > *:not(:first-child) {
    margin-top: 30px;
  }
}
.tt-services-aside {
  border: 1px solid $border;
  padding: 21px 19px 14px 19px;
  @include border-radius($border_radius);
  .tt-services-block {
    &:not(:first-child) {
      border-top: 1px solid $border;
      padding: 22px 0 0 0;
    }
    &:not(:last-child) {
      padding-bottom: 13px;
    }
    .tt-col-icon {
      margin-left: -4px;
      [class^="icon-"] {
        top: -8px;
      }
    }
    .tt-col-icon + .tt-col-description {
      padding-left: 8px;
    }
    .tt-col-description {
      p {
        margin-top: 1px;
      }
    }
  }
  .tt-promo-brand {
    max-width: 514px;
  }
}
@media (min-width: 1025px) and (max-width: 1229px) {
  .tt-row-custom-01.tt-responsive-lg {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    .col-item:not(:first-child) {
      margin-top: 15px;
    }
  }
}
/* tt-img-box (index-02.html)*/
.tt-img-box-listing {
  margin-top: -20px;
  .tt-img-box {
    margin-top: 20px;
  }
}
.tt-img-box {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid $img_box_border;
  text-decoration: none;
  position: relative;
  text-align: center;
  @include border-radius($border_radius);
  @include animation-value(border $value_speed);
  img {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
  &:before {
    content: "";
    width: 100%;
    height: 100%;
    border: 1px solid;
    border-color: inherit;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    @include animation-value(opacity $value_speed);
    @include opacity(0);
  }
  &:hover {
    border-color: $img_box_border_hover;
  }
  &:hover:before {
    @include opacity(1);
  }
  padding: 25%;
  @media (min-width: 1025px) {
    height: 138px;
  }
  @media (max-width: 1024px) and (min-width: 576px) {
    height: 118px;
  }
  @media (max-width: 575px) and (min-width: 421px) {
    height: 100px;
  }
  @media (max-width: 420px) {
    height: 100px;
  }
}
/* tt-layout-vertical-listing */
.tt-layout-vertical-listing {
  .tt-item:not(:first-child) {
    margin-top: 18px;
  }
}
.tt-layout-vertical {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  .tt-img {
    max-width: 100%;
    margin-top: 2px;
    position: relative;
    img {
      width: 100%;
      max-width: 100px;
      height: auto;
    }
    .tt-img-default {
      -webkit-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
      overflow: hidden;
      top: 0;
      left: 0;
    }
    .tt-img-roll-over {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
      z-index: 1;
      width: 100%;
      -webkit-transition: opacity 0.3s ease-out;
      transition: opacity 0.3s ease-out;
    }
  }
  .tt-img + .tt-description {
    padding-left: 20px;
  }
  .tt-title {
    font-size: 14px;
    font-family: $default_font;
    font-weight: 400;
    color: $layout_vertical_title;
    a {
      color: $layout_vertical_title;
      &:hover {
        color: $layout_vertical_title_hover;
      }
    }
  }
  .tt-description {
    .tt-rating {
      margin-left: -1px;
    }
    .tt-add-info {
      @extend ._reset-ul-style;
      margin-top: 3px;
      li {
        font-size: 12px;
        color: $layout_vertical_info;
        a {
          color: $layout_vertical_info;
        }
        a:hover {
          color: $layout_vertical_info_hover;
        }
      }
    }
    .tt-price {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      color: $layout_vertical_price;
      .new-price {
        color: $layout_vertical_price_new;
        margin-right: 7px;
      }
      .old-price {
        color: $layout_vertical_price_old;
        text-decoration: line-through;
      }
      span {
        display: inline-block;
      }
    }
    > *:nth-child(1) {
      margin-top: 0;
    }
  }
  ul.tt-options-swatch {
    margin: 12px 0px 0 -2px;
  }
  ul.tt-options-swatch li {
    margin: 4px 2px 0;
  }
}
.tt-layout-vertical:hover {
  .tt-img {
    .tt-img-default + .tt-roll-over {
      @include opacity(0);
    }
    .tt-img-roll-over,
    [class^="tt-img"]:last-child {
      @include opacity(1);
    }
  }
}
/* tt-tabs-default */
.tt-block-title + .tt-tab-wrapper {
  position: relative;
  @media (min-width: 1025px) {
    margin-top: -65px;
  }
}
.tt-tab-wrapper {
  position: relative;
  @media (min-width: 1025px) {
    margin-top: -1px;
  }
}
// header checking indent
@media (min-width: 1025px) {
  .arrow-location-tab {
    margin-top: 90px;
  }
  .tt-block-title + .arrow-location-tab.tt-carousel-products {
    margin-top: -38px;
  }

  .tab-content {
    .arrow-location-tab {
      margin-top: -51px;
    }
  }
  .tt-block-title + .tab-content .arrow-location-tab.tt-carousel-products {
    margin-top: -52px;
  }
}
.tt-tabs-default {
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  z-index: 3;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-align-content: flex-start;
  -ms-flex-line-pack: start;
  align-content: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  li {
    > a {
      color: $tabsDefault_nav_text;
      font-size: 14px;
      letter-spacing: 0.02em;
      font-family: $default_font2;
      font-weight: 500;
      @include animation-value(color $value_speed);
      padding: 5px 13px;
      display: inline-block;
    }
    > a:hover {
      color: $tabsDefault_nav_text_hover;
    }
    > a.active {
      color: $tabsDefault_nav_text_active;
    }
  }
  @media (min-width: 1025px) {
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 130px;
    padding-right: 130px;
    padding-bottom: 29px;
  }
  @media (max-width: 1024px) {
    margin-left: -14px;
    padding-bottom: 29px;
  }
  @media (max-width: 575px) {
    padding-bottom: 19px;
    margin-top: -5px;
  }
}
.tt-tabs-default + .tab-content {
  .tab-pane:not(.active) {
    display: none;
  }
}
.tt-tab-wrapper {
  .tt-tabs-default {
    @media (min-width: 1025px) {
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding-left: 130px;
      padding-right: 130px;
      padding-bottom: 49px;
    }
    @media (max-width: 1024px) {
      margin-left: -14px;
      padding-bottom: 31px;
    }
    @media (max-width: 575px) {
      padding-bottom: 20px;
      margin-top: -5px;
    }
  }
}
/* tt-text-box01 */
.tt-text-box01 {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  max-width: 862px;
  margin: 0 auto;
  > *:nth-child(1) {
    margin-top: 0;
  }
}
/* tt-content-info */
.tt-content-info {
  text-align: center;
  text-decoration: none;
  display: block;
  position: relative;
  color: $contentInfo_text;
  .tt-title {
    font-size: 24px;
    line-height: 34px;
    padding-bottom: 23px;
    color: $contentInfo-title;
    @include animation-value(color $value_speed);
  }
  p {
    margin-top: 0;
  }
  .tt-subscription {
    margin-top: 20px;
    .tt-text-large {
      font-size: 16px;
      color: $contentInfo_text_large;
      font-weight: 500;
      font-family: $default_font2;
    }
    .tt-text-small {
      color: $contentInfo_text_small;
      font-size: 12px;
    }
  }
  &:hover {
    color: $contentInfo_text;
    .tt-title {
      color: $contentInfo_title_hover;
    }
  }
  @media (min-width: 1230px) {
    padding-left: 20%;
    padding-right: 20%;
  }
  @media (max-width: 1229px) {
    padding-left: 15%;
    padding-right: 15%;
  }
}
.tt-slider-fullwidth {
  position: relative;
  &.arrow-location-center-02:after,
  &.arrow-location-center-02:before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    z-index: 1;
    @media (min-width: 401px) {
      width: 70px;
    }
    @media (max-width: 400px) {
      width: 55px;
    }
  }
  &.arrow-location-center-02:after {
    background: -webkit-linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    background: -o-linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    background: -moz-linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    right: 0;
  }
  &.arrow-location-center-02:before {
    content: "";
    background: -webkit-linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    background: -o-linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    background: -moz-linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      $default_bg_color 50%
    );
    left: 0;
  }
}
/* tt-carousel-brands */
.tt-carousel-brands {
  margin: 4px 0px -4px;
  .slick-slide {
    text-align: center;
  }
  a {
    display: inline-block;
    padding: 0 20px;
  }
  a img {
    height: auto;
    width: 100%;
    max-width: 131px;
    @include opacity(0.2);
  }
  a:hover img {
    @include opacity(1);
  }
}
html:not(.mac):not(.touch-device) {
  .tt-carousel-brands {
    .slick-track {
      display: -ms-flexbox;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-align-content: center;
      -ms-flex-line-pack: center;
      align-content: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
    }
  }
}
/* tt-items-categories */
.tt-categories-listing {
  margin-top: -50px;
  .tt-items-categories {
    margin-top: 50px;
  }
}
.tt-items-categories {
  .tt-title-block {
    .tt-title {
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 0.03em;
      @include animation();
    }
    img {
      width: 100%;
      max-width: 100%;
      height: auto;
      margin-top: 17px;
    }
    > *:nth-child(1) {
      margin-top: 0;
    }
  }
  ul {
    @extend ._reset-ul-style;
    margin-top: 11px;
    padding-bottom: 3px;
    li {
      color: $items_categories_list_text;
      a {
        color: $items_categories_list_text;
        padding: 3px 0 3px 0;
        display: inline-block;
        @include animation-value(color $value_speed);
        &:hover {
          color: $items_categories_list_text_hover;
        }
      }
      &.active {
        a {
          color: $items_categories_list_text_active;
        }
      }
    }
  }
  .tt-title-block + [class^="btn"] {
    margin-top: 14px;
  }
  > *:nth-child(1) {
    margin-top: 0;
  }
}
.tt-items-categories.active {
  .tt-title {
    color: $items_categories_title_hover;
  }
}
/* tt-loader (quickViewModal)*/
.tt-loader {
  height: 100%;
  width: 100%;
  position: absolute;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  top: 0;
  svg path,
  svg rect {
    fill: $ttLoader_bg;
  }
}

/* max oc */
@media (min-width: 576px) {
  .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 3px;
    padding-bottom: 1px;
    line-height: 2.9;
  }
  footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 3px;
    padding-bottom: 1px;
    line-height: 2.9;
  }
  .form-default .form-control[type="text"],
  .form-default .form-control[type="email"],
  .form-default .form-control[type="password"] {
    padding-top: 3px;
    padding-bottom: 1px;
    line-height: 2.9;
  }
}
@media (max-width: 575px) {
  .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.4;
  }
  footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.4;
  }
  .form-default .form-control[type="text"],
  .form-default .form-control[type="email"],
  .form-default .form-control[type="password"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.4;
  }
}
/* ie */
@media (min-width: 576px) {
  html.ie.win .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 2px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
  html.ie.win
    footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 2px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
  html.ie.win .form-default .form-control[type="text"],
  html.ie.win .form-default .form-control[type="email"],
  html.ie.win .form-default .form-control[type="password"] {
    padding-top: 2px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
}
@media (max-width: 575px) {
  html.ie.win .tt-modal-newsletter .input-group input[type="email"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
  html.ie.win
    footer
    .tt-footer-default
    .tt-newsletter
    .form-default
    .form-group
    .form-control {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
  .form-default .form-control[type="text"],
  .form-default .form-control[type="email"],
  .form-default .form-control[type="password"] {
    padding-top: 1px;
    padding-bottom: 1px;
    line-height: 2.6;
  }
}
/**/

.tt-offset-35 {
  margin-top: 35px;
  @media (max-width: 575px) {
    margin-top: 24px;
  }
}
.tt-offset-20 {
  margin-top: 20px;
}
.tt-offset-16 {
  margin-top: 16px;
}
.tt-offset-7 {
  margin-top: 7px;
}

/*
 	visibility of elements
 */
/* hidden */
@media (min-width: 1230px) {
  .hidden-lg-up {
    display: none;
  }
}
@media (max-width: 1230px) and (min-width: 1025px) {
  .hidden-md-up {
    display: none;
  }
}
@media (max-width: 1025px) and (min-width: 790px) {
  .hidden-sm-up {
    display: none;
  }
}
@media (max-width: 789px) and (min-width: 575px) {
  .hidden-sm {
    display: none;
  }
}
@media (max-width: 575px) {
  .hidden-xs {
    display: none;
  }
}
/* visibility */
.visible-xs,
.visible-sm,
.visible-md,
.visible-lg,
.visible-xl {
  display: none;
}
@media (min-width: 1230px) {
  .visible-xl {
    display: block;
  }
}
@media (max-width: 1230px) and (min-width: 1025px) {
  .visible-lg {
    display: block;
  }
}
@media (max-width: 1025px) and (min-width: 789px) {
  .visible-md {
    display: block;
  }
}
@media (max-width: 789px) and (min-width: 575px) {
  .visible-sm {
    display: block;
  }
}
@media (max-width: 575px) {
  .visible-xs {
    display: block;
  }
}
.hide {
  display: none !important;
}
@media (max-width: 1024px) {
  .tt-hidden-mobile {
    display: none !important;
  }
}
@media (min-width: 1025px) {
  .tt-hidden-desctope {
    display: none !important;
  }
}
.no-gutter > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}
.no-radius {
  border-radius: 0 !important;
}
/* color text */
.tt-base-color {
  color: $base-color;
}
.tt-base-dark-color {
  color: $base-dark-color;
}
.tt-white-color {
  color: $white-color;
}
.tt-light-green-color {
  color: $tt-light-green-color;
}
/* bg color */
.tt-color-bg-01 {
  background-color: $color-bg-01;
}
.tt-color-bg-02 {
  background-color: $color-bg-02;
}
.tt-color-bg-03 {
  background-color: $color-bg-03;
}
.tt-color-bg-04 {
  background-color: $color-bg-04;
}
.tt-color-bg-05 {
  background-color: $color-bg-05;
}
.tt-color-bg-06 {
  background-color: $color-bg-06;
}
.tt-color-bg-07 {
  background-color: $color-bg-07;
}
.tt-color-bg-08 {
  background-color: $color-bg-08;
}
.tt-color-bg-09 {
  background-color: $color-bg-09;
}
.tt-color-bg-10 {
  background-color: $color-bg-10;
}
.tt-color-bg-11 {
  background-color: $color-bg-11;
}
.tt-color-bg-12 {
  background-color: $color-bg-12;
}
.tt-color-bg-13 {
  background-color: $color-bg-13;
}
.tt-color-bg-14 {
  background-color: $color-bg-14;
}
.tt-color-bg-15 {
  background-color: $color-bg-15;
}
.tt-color-bg-16 {
  background-color: $color-bg-16;
}
.tt-color-bg-17 {
  background-color: $color-bg-17;
}
.tt-color-bg-18 {
  background-color: $color-bg-18;
}
.estatico-centro {
  display: flex;
  flex-direction: column;
  place-items: center;
}
.ocultar {
  display: none;
}

.avisos {
  background: transparent;
  z-index: 10;
  position: fixed;
  top: 20%;
}

.filtro_principal {
  background: $default_color !important;
  color: white !important;
  opacity: 0.8 !important;
}

.CookieConsent {
  align-items: baseline !important;
  background: $h_scheme01_bg !important;
  color: $h_scheme01_menu_desctope_link_text !important;
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-between !important;
  left: 0px !important;
  position: fixed !important;
  width: 100% !important;
  z-index: 999 !important;
  bottom: 0px !important;

  button {
    background-color: $default_color !important;
    font-size: "13px" !important;
    color: $h_scheme01_menu_desctope_link_text !important;
  }
}

$menu_familias_bg: $h_scheme01_bg !default;

.menu-familias {
  .tt-color-scheme-01 {
    background-color: $menu_familias_bg;
  }
}

$menu_informes_bg: $h_scheme01_bg !default;

.menu-informes {
  .tt-color-scheme-01 {
    background-color: $menu_informes_bg;
  }
}
