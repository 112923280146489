/* Magnific Popup CSS */
.mfp-bg {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 21;
    overflow: hidden;
    position: fixed;
    background: $magnific_popup_bg;
}
.mfp-wrap {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 22;
    position: fixed;
    outline: none;
    -webkit-backface-visibility: hidden;
}
.mfp-container {
    text-align: center;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    padding: 20px 8px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
.mfp-container:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}
.mfp-align-top .mfp-container:before {
    display: none;
}
.mfp-content {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    margin: 0 auto;
    text-align: left;
    z-index: 20;
}
.mfp-inline-holder .mfp-content, .mfp-ajax-holder .mfp-content {
    width: 100%;
    cursor: auto;
  }
.mfp-ajax-cur {
    cursor: progress;
}
.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
 }
.mfp-zoom {
    cursor: pointer;
 }
.mfp-auto-cursor .mfp-content {
    cursor: auto;
}
.mfp-close, .mfp-arrow,
.mfp-preloader, .mfp-counter {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}
.mfp-loading.mfp-figure {
   display: none;
}
.mfp-hide {
    display: none;
}
.mfp-preloader {
    color: #CCC;
    position: absolute;
    top: 50%;
    width: auto;
    text-align: center;
    margin-top: -0.8em;
    left: 8px;
    right: 8px;
    z-index: 20;
}
.mfp-preloader a {
    color: #cccccc;
}
.mfp-preloader a:hover {
    color: #ffffff;
}
.mfp-s-ready .mfp-preloader {
    display: none;
}
.mfp-s-error .mfp-content {
   display: none;
}
button.mfp-close, button.mfp-arrow {
    overflow: visible;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    display: block;
    outline: none;
    padding: 0;
    z-index: 21;
    -webkit-box-shadow: none;
    box-shadow: none;
}
button::-moz-focus-inner {
    padding: 0;
    border: 0;
}
.mfp-close {
    width: 44px;
    height: 44px;
    line-height: 44px;
    position: absolute;
    right: -43px;
    top: -44px;
    text-decoration: none;
    text-align: center;
    padding: 0 0 18px 10px;
    color: $magnific_popup_bt_close;
    font-size: 0px;
    line-height: 0;
    font-family: $default_font_icon;
    @include animation();
    &:before{
        content: "\ea0f";
        font-size: 20px;
        line-height: 1;
    }
    &:hover {
        color: $magnific_popup_bt_close_hover;
    }
}
.mfp-counter {
    top: 0;
    right: 0;
    color: $magnific_popup_counter;
    font-size: 14px;
    line-height: 18px;
    font-weight: lighter;
    white-space: nowrap;
}
button.mfp-arrow {
    position: absolute;
    margin: 0;
    top: 50%;
    margin-top: -25px;
    padding: 0;
    width: 50px;
    height: 50px;
    @include animation();
}
.mfp-arrow:hover,
.mfp-arrow:focus {
    opacity: 1;
    filter: alpha(opacity=100);
}
.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
    border: medium inset transparent;
}
.mfp-arrow{
    display: block;
    font-family: $default_font_icon;
    font-size: 30px;
    line-height: 45px;
    color: $magnific_popup_arrow_icon;
    opacity: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.mfp-arrow:hover,
button.mfp-arrow:hover{
    color:$magnific_popup_arrow_icon_hover;
}
.mfp-arrow:before{
    display: block;
}
.mfp-arrow-right{
   right: 10px;
}
.mfp-arrow-right:before{
    content: "\e90e";
    left: 10px;
}
.mfp-arrow-left:before{
    content: "\e90d";
    left: 7px;
}
.mfp-arrow-left{
   left: 0;
}
.mfp-iframe-holder {
    padding-top: 40px;
    padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
    line-height: 0;
    width: 100%;
    max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
    top: -40px;
}
.mfp-iframe-scaler {
    width: 100%;
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
      background: #000;
}

/* Main image in popup */
img.mfp-img {
    width: auto;
    max-width: 100%;
    height: auto;
    display: block;
    line-height: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0px 0 40px;
    margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
    line-height: 0;
}
.mfp-figure:after {
    content: '';
    position: absolute;
    left: 0;
    top: 40px;
    bottom: 40px;
    display: block;
    right: 0;
    width: auto;
    height: auto;
    z-index: -1;
    box-shadow: 0 0 8px $magnific_popup_shadow;
    background: #444;
}
.mfp-figure small {
    color: #BDBDBD;
    display: block;
    font-size: 12px;
    line-height: 14px;
}
.mfp-figure figure {
    margin: 0;
}
.mfp-bottom-bar {
    margin-top: -21px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: auto;
    text-align: center;
    font-family: $default_font2;
}
.mfp-title {
    color: #fff;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    word-wrap: break-word;
}
.mfp-image-holder .mfp-content {
    max-width: 72%;
}
.mfp-gallery img.mfp-img{
  max-height: 80vh !important;
}
.mfp-gallery .mfp-image-holder .mfp-figure {
    cursor: pointer;
}
@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
    /**
    * Remove all paddings around the image on small screen
    */
    .mfp-img-mobile .mfp-image-holder {
        padding-left: 0;
        padding-right: 0;
    }
    .mfp-img-mobile img.mfp-img {
        padding: 0;
    }
    .mfp-img-mobile .mfp-figure:after {
        top: 0;
        bottom: 0;
    }
    .mfp-img-mobile .mfp-figure small {
        display: inline;
        margin-left: 5px;
    }
    .mfp-img-mobile .mfp-bottom-bar {
        background: rgba(0, 0, 0, 0.6);
        bottom: 0;
        margin: 0;
        top: auto;
        padding: 3px 5px;
        position: fixed;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
    }
    .mfp-img-mobile .mfp-bottom-bar:empty {
        padding: 0;
    }
    .mfp-img-mobile .mfp-counter {
        right: 5px;
        top: 3px;
    }
    .mfp-img-mobile .mfp-close {
      top: 0;
      right: 0;
      width: 35px;
      height: 35px;
      line-height: 35px;
      background: rgba(0, 0, 0, 0.6);
      position: fixed;
      text-align: center;
      padding: 0;
    }
 }

@media all and (max-width: 900px) {
    .mfp-arrow {
        -webkit-transform: scale(0.75);
        transform: scale(0.75);
    }
    .mfp-arrow-left {
        -webkit-transform-origin: 0;
        transform-origin: 0;
    }
    .mfp-arrow-right {
        -webkit-transform-origin: 100%;
        transform-origin: 100%;
    }
    .mfp-container {
        padding-left: 6px;
        padding-right: 6px;
    }
 }
.mfp-ie7 .mfp-img {
    padding: 0;
}
.mfp-ie7 .mfp-bottom-bar {
    width: 600px;
    left: 50%;
    margin-left: -300px;
    margin-top: 5px;
    padding-bottom: 5px;
}
.mfp-ie7 .mfp-container {
    padding: 0;
}
.mfp-ie7 .mfp-content {
    padding-top: 44px;
}
.mfp-ie7 .mfp-close {
    top: 0;
    right: 0;
    padding-top: 0;
}